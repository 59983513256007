export const AgreementNFTs = `CLAIM AGREEMENT FOR QUERIO (“QRO”) TOKENS

This Agreement ("Agreement") is entered into by and between the undersigned party ("NFT holder") and S.C. CROSSCHAIN LABS S.R.L. ("Company"), registered in Bucharest, Romania, under the European Unique Identifier ROONRC.J40/623/2021. As the undersigned party, you confirm that you have read and understood this Agreement in relation to the QRO token claim process. By proceeding with the token claim process, you accept the terms stated herein.

1. Representations and Warranties of the NFT holder

In relation to the claim of QRO tokens, the undersigned party hereby confirms that:
1.1. It acknowledges that all information provided during this Agreement is accurate.
1.2. It controls the provided addresses and assumes full responsibility for the protection of the private key(s) and the appropriate handling of the claim process, all of which are at their full and sole risk.
1.3. It understands the inherent risks and lack of warranties associated with QRO Tokens. 
1.4. It is compliant with all applicable laws and regulations related to token acquisition and holds the Company harmless for any breaches.
1.5. It is responsible for all tax implications related to QRO Tokens in their jurisdiction. 
1.6. It is not a U.S. Person as defined in Regulation S under the U.S. Securities Act of 1933, and they comply with all relevant restrictions and regulations thereof. No direct selling efforts (as defined in Rule 902(c) of Regulation S) were made in the United States; and it is not acquiring the Tokens for the account or benefit of any U.S. Person. 
1.7. It will not sell or assign the non-transferrable portion of the QRO Tokens until the release of QRO tokens.
1.8. It understands and expressly accepts that there is no warranty whatsoever on Querio Tokens and/or the success of the Querio Platform, expressed or implied, to the extent permitted by law. Querio Tokens are created and obtained at the sole risk of the undersigning party on an “as is” and “under development” basis and without, to the extent permitted by law, any warranties of any kind, including, but not limited to, warranties of title or implied warranties, merchantability, or fitness for a particular purpose.
1.9. It acknowledges and agree that the Tokens will be deemed to bear the legend set forth below (in addition to any other legend required by applicable U.S. federal, state or non-U.S. securities laws or provided in any other agreement with the Company or its affiliates):
THE TOKENS HAVE NOT BEEN REGISTERED UNDER THE SECURITIES ACT OF 1933, AS AMENDED (THE “ACT”) WITH THE UNITED STATES SECURITIES AND EXCHANGE COMMISSION, AND THE FOUNDATION DOES NOT INTEND TO REGISTER THEM. PRIOR TO THE ONE-YEAR ANNIVERSARY OF THE DATE THE TOKEN IS DELIVERED TO YOU (THE “ONE-YEAR DEADLINE”), THE TOKENS MAY NOT BE OFFERED OR SOLD (INCLUDING OPENING A SHORT POSITION IN SUCH TOKENS) IN THE UNITED STATES OR TO U.S. PERSONS (AS DEFINED BY RULE 902(k) ADOPTED UNDER THE ACT), OTHER THAN TO DISTRIBUTORS, UNLESS THE TOKENS ARE REGISTERED UNDER THE ACT, OR AN EXEMPTION FROM THE REGISTRATION REQUIREMENTS OF THE ACT IS AVAILABLE. PURCHASERS OF TOKENS PRIOR TO THE ONE-YEAR DEADLINE MAY RESELL SUCH TOKENS ONLY PURSUANT TO AN EXEMPTION FROM REGISTRATION UNDER THE ACT OR OTHERWISE IN ACCORDANCE WITH THE PROVISIONS OF REGULATION S OF THE ACT, OR IN TRANSACTIONS EFFECTED OUTSIDE OF THE UNITED STATES, PROVIDED, IN EACH CASE, THEY DO NOT SOLICIT (AND NO ONE ACTING ON THEIR BEHALF SOLICITS) PURCHASERS IN THE UNITED STATES OR OTHERWISE ENGAGE(S) IN SELLING EFFORTS IN THE UNITED STATES AND PROVIDED THAT HEDGING TRANSACTIONS INVOLVING THESE TOKENS MAY NOT BE CONDUCTED UNLESS IN COMPLIANCE WITH THE ACT. A HOLDER OF THE TOKENS WHO IS A DISTRIBUTOR, DEALER, SUB- UNDERWRITER OR OTHER SECURITIES PROFESSIONAL, IN ADDITION, CANNOT PRIOR TO THE ONE-YEAR DEADLINE, RESELL THE TOKENS TO A U.S. PERSON AS DEFINED BY RULE 902(k) OF REGULATION S UNLESS THE TOKENS ARE REGISTERED UNDER THE ACT OR AN EXEMPTION FROM REGISTRATION UNDER THE ACT IS AVAILABLE.

2. Token Allocation and Distribution

2.1. The Company shall allocate QRO Tokens to the NFT holder in accordance with the details outlined in Annex A, which is attached hereto and incorporated herein by reference.
2.2. The Company may in its discretion determine when the QRO Token Launch will take place and inform the NFT holder of such information on the Company's official communication channels.
2.3. The NFT holder shall receive their QRO Tokens in the form of a basket consisting of five equal-value portions. The initial portion will be immediately disbursed as liquid QRO tokens. Subsequent portions will become available at intervals of 6, 12, 18, and 24 months, respectively.

3. Legal Entity Representations (if applicable)

If the NFT holder is a legal entity, it confirms that:
3.1. It is duly organized, in good standing, and legally entitled to perform the token claim process in its jurisdiction.
3.2. There was no change of the beneficial ownership of the legal entity since passing the KYC procedures. 
3.3. The person(s) signing are fully authorized to represent and legally act on behalf of the legal entity in relation to purchasing the QRO Tokens and signing this Agreement.

4. Limitation of Liability; Indemnification; Arbitration

4.1. Governing Law. This Agreement shall be governed by and construed in accordance with Romanian law. The application of the United Nations Convention for Contracts for the International Sales of Goods is hereby explicitly excluded. 

4.2. Dispute Resolution. Any dispute, controversy or claim arising out of or in connection with this Statement or the breach, termination, existence, legal competence or invalidity thereof, shall be exclusively settled by the courts of Bucharest, Romania.  

4.3. Limitation of Liability. To the fullest extent permitted by applicable law, NFT holder disclaims any right or cause of action against Company of any kind in any jurisdiction that would give rise to any Damages whatsoever, on the part of Company. Company shall not be liable to NFT holder for any type of damages, whether direct, indirect, incidental, special, punitive, consequential or exemplary (including damages for lost profits, goodwill, use or data), even if and notwithstanding the extent to which Company has been advised of the possibility of such damages. NFT holder agrees not to seek any refund, compensation or reimbursement from a Company Party, regardless of the reason, and regardless of whether the reason is identified in this Agreement. “Damages” means any and all damages (including direct, indirect, incidental, special, punitive, consequential or exemplary etc), losses, taxes, liabilities, claims, judgments, penalties, payments, interest, costs and expenses (including reasonable and documented legal fees, accountants’ fees and expert witnesses’ fees and expenses incurred in investigating and/or prosecuting any claim for indemnification).
 
4.4. Damages. In no circumstances will the aggregate joint liability of the Company Parties, whether in contract, warrant, tort or other theory, for Damages to NFT holder under this Agreement exceed the amount received by Company from NFT holder.
 
4.5. Force Majeure. NFT holder understands and agrees that Company shall not be liable and disclaims all liability to NFT holder in connection with any force majeure event, including acts of God, labor disputes or other industrial disturbances, electrical, telecommunications, hardware, software or other utility failures, software or smart contract bugs or weaknesses, earthquakes, storms, or other nature-related events, blockages, embargoes, riots, acts or orders of government, acts of terrorism or war, technological change, changes in interest rates or other monetary conditions, and, for the avoidance of doubt, changes to any blockchain-related protocol.

4.6.  No Partnership; No Agency; No Third Party Beneficiaries. Nothing in this Agreement and no action taken by the Parties shall constitute, or be deemed to constitute, a partnership, association, joint venture or other co-operative entity between the Parties. Nothing in this Agreement and no action taken by the Parties pursuant to this Agreement shall constitute, or be deemed to constitute, either Party the agent of the other Party for any purpose. No Party has, pursuant to this Agreement, any authority or power to bind or to contract in the name of the other Party. This Agreement does not create any third party beneficiary rights for any person.


Privacy Notice and GDPR Compliance

1. Introduction
S.C. CROSSCHAIN LABS S.R.L. ("we," "our," or "us") is committed to protecting your privacy and complying with applicable data protection laws, including the General Data Protection Regulation (GDPR). This Privacy Notice explains how we collect, use, disclose, and safeguard your personal data when you use our services or interact with us. It also outlines your rights regarding your personal data and how you can exercise them.

2. Information We Collect
We may collect various types of personal data, including:
Financial Information (e.g., payment details, transaction history)
Technical Information (e.g. cookies)
Any other information you choose to provide.

3. How We Use Your Information
We may use personal data for the following purposes:
3.1. Agreements and Date-Related Terms. We collect and process personal data for the purpose of creating, maintaining, and enforcing agreements between you and S.C. CROSSCHAIN LABS S.R.L. This includes the use of personal data to specify relevant dates within these agreements, such as effective dates, purchase dates, delivery dates, or termination dates. The processing of personal data for agreements is essential to provide our services and ensure compliance with legal obligations.

4. Data Protection Rights
You have certain rights under the GDPR, including:
The right to access your personal data
The right to rectify inaccuracies in your personal data
The right to erasure (right to be forgotten)
The right to restrict processing
The right to data portability
The right to object to processing
Rights related to automated decision-making and profiling.

5. Data Security
We are committed to protecting your personal data and have implemented security measures to prevent unauthorized access, disclosure, alteration, or destruction of your data. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.

6. Contact Information
If you have any questions or requests regarding your personal data or this Privacy Notice, please contact us at support@querio.io

7. Updates to This Privacy Notice
We may update this Privacy Notice to reflect changes in our practices or legal requirements. Updates will be posted on our website, and the date of the latest revision will be indicated.
`;