import {
    TextField,
} from "@mui/material";

import styled from '@emotion/styled';


export const CssTextField = styled(TextField)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
        width: '29rem',
    },
    [theme.breakpoints.up('md')]: {
        width: '45rem',
    },
    [theme.breakpoints.up('lg')]: {
        width: '45rem',
    },
    [theme.breakpoints.up('xl')]: {
        width: '45rem',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        '& fieldset': {
            borderColor: '#99999980',
            opacity: '0.5',
            boxShadow: theme.palette.mode === 'light' ? '0px 2px 4px 0px rgba(0, 0, 0, 0.1), 0px 2px 16px 0px rgba(0, 0, 0, 0.1)' : '',
        },
        '&:hover fieldset': {
            borderColor: '#99999980',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#99999980',
            backgroundColor: 'transparent',
            boxShadow: theme.palette.mode === 'light' ? '0px 2px 4px 0px rgba(0, 0, 0, 0.1), 0px 2px 16px 0px rgba(0, 0, 0, 0.1)' : '',
        },
    },
}));