import {
    Box,
} from "@mui/material";

import styled from '@emotion/styled';


export const ResultsContainer = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.primary.main,

    height: '100%',

    paddingTop: '1.5rem',
    paddingBottom: '5rem',

    [theme.breakpoints.down('md')]: {
        paddingLeft: '1.4rem',
        paddingRight: '1.4rem',
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: '7.7rem',
        paddingRight: '1.4rem',
    },
    [theme.breakpoints.up('lg')]: {
        paddingLeft: '14.7rem',
        paddingRight: '25rem',
    },
}));