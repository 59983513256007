import { useState } from 'react';
import { Container, Typography, Stack, Button, TextField, IconButton, useMediaQuery, Box, Divider } from '@mui/material';
import { Principal } from '@dfinity/principal';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

import AssetHeader from './AssetsHeader';
import Claim from './Claim';
import Popup from './Popup';
import QuerioLogo from '../assets/QuerioLogo.svg';
import ICPLogo from '../assets/ICPLogo.svg';
import ExportLogo from '../assets/ExportLogo.svg';

import { idlFactory as SeedFundersBackendIDL } from '../idls/seed_funders_backend.did';

const { QUERIO_FUNDED, QUERIO_PRESALE, QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID } = require('../config');

const WHITELIST = [QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID];

function trimPrincipal(principal) {
    const firstDashIndex = principal.indexOf('-');
    const lastDashIndex = principal.lastIndexOf('-');

    return `${principal.substring(0, firstDashIndex + 1)}...${principal.substring(lastDashIndex)}`;
}

function formatNumberWithSeparator(number) {
    const str = number.toString();
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

function isValidPrincipal(p) {
    try {
        return (p === Principal.fromText(p).toText());
    } catch (e) {
        return false;
    }
}

function AssetTreasury() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [amountToClaimQRO, setAmountToClaimQRO] = useState(0);
    const [showClaim, setShowClaim] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState('');
    const [popupMessage, setPopupMessage] = useState('');

    const [principal, setPrincipal] = useState('');
    const [amountNFTs, setAmountNFTs] = useState(0);
    const [amountTier1, setAmountTier1] = useState(0);
    const [amountTier2, setAmountTier2] = useState(0);

    const [recipientPrincipalNFTs, setRecipientPrincipalNFTs] = useState('');
    const [recipientPrincipalTier1, setRecipientPrincipalTier1] = useState('');
    const [recipientPrincipalTier2, setRecipientPrincipalTier2] = useState('');

    const [recipientPrincipalNFTsFromBackend, setRecipientPrincipalNFTsFromBackend] = useState('');
    const [recipientPrincipalTier1FromBackend, setRecipientPrincipalTier1FromBackend] = useState('');
    const [recipientPrincipalTier2FromBackend, setRecipientPrincipalTier2FromBackend] = useState('');

    const [recipientPrincipalNFTsEdit, setRecipientPrincipalNFTsEdit] = useState(false);
    const [recipientPrincipalTier1Edit, setRecipientPrincipalTier1Edit] = useState(false);
    const [recipientPrincipalTier2Edit, setRecipientPrincipalTier2Edit] = useState(false);

    const [isValidRecipientPrincipalNFTs, setIsValidRecipientPrincipalNFTs] = useState(true);
    const [isValidRecipientPrincipalTier1, setIsValidRecipientPrincipalTier1] = useState(true);
    const [isValidRecipientPrincipalTier2, setIsValidRecipientPrincipalTier2] = useState(true);

    const matches = useMediaQuery('(min-width:600px)');

    const navigate = useNavigate();

    const handleNavigationFunded = () => {
        window.open('https://dashboard.internetcomputer.org/neuron/18302312318872763647', '_blank');
    };

    const handleNavigationPesale1 = () => {
        window.open('https://dashboard.internetcomputer.org/neuron/12627325115786836322', '_blank');
    };

    const handleNavigationPesale2 = () => {
        window.open('https://dashboard.internetcomputer.org/neuron/1058501368467774741', '_blank');
    };

    const showPopupClaim = (message) => {
        setShowPopup(true);
        setPopupType('claim');
        setPopupMessage(message);
    }

    const showPopupSaving = () => {
        setShowPopup(true);
        setPopupType('saving');
    }

    const showPopupLoading = () => {
        setShowPopup(true);
        setPopupType('loading');
    }

    const showPopupError = (message) => {
        setShowPopup(true);
        setPopupType('error');
        setPopupMessage(message);
    }

    const showPopupWarning = (message) => {
        setShowPopup(true);
        setPopupType('warning');
        setPopupMessage(message);
    }

    const handleLoginWithPlug = async () => {
        setPopupType('waitforlogin');

        try {
            const hasAllowed = await window.ic?.plug?.requestConnect({
                whitelist: WHITELIST,
            });

            if (hasAllowed) {
                let principal = await window.ic.plug.agent.getPrincipal();
                //let accountId = getAccountId(principal);
                let plugPrincipal = Principal.from(principal).toString();
                setPrincipal(plugPrincipal);

                const actor = await window.ic.plug.createActor({ canisterId: QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID, interfaceFactory: SeedFundersBackendIDL });
                //const unclaimedNFTs = await actor.hasUnclaimedNFTs();
                const dataNFTs = await actor.getNFTs();
                const dataTier1 = await actor.getTier1();
                const dataTier2 = await actor.getTier2();

                setShowPopup(false);

                /*
                if (unclaimedNFTs?.length > 0 && unclaimedNFTs[0]?.amountQRO) {
                    setAmountToClaimQRO(unclaimedNFTs[0]?.amountQRO);
                    showPopupClaim(`You have ${unclaimedNFTs[0]?.amountQRO} QRO rewards unclaimed.`)
                }
                */
                if (dataNFTs?.length > 0 && dataNFTs[0]?.amountQRO) {
                    setAmountNFTs(dataNFTs[0]?.amountQRO);
                    setRecipientPrincipalNFTsFromBackend(dataNFTs[0]?.updatedRecipientPrincipal);
                    setRecipientPrincipalNFTs(dataNFTs[0]?.updatedRecipientPrincipal);
                }
                if (dataTier1?.length > 0 && dataTier1[0]?.amountQRO) {
                    setAmountTier1(dataTier1[0]?.amountQRO);
                    setRecipientPrincipalTier1FromBackend(dataTier1[0]?.updatedRecipientPrincipal);
                    setRecipientPrincipalTier1(dataTier1[0]?.updatedRecipientPrincipal);
                }
                if (dataTier2?.length > 0 && dataTier2[0]?.amountQRO) {
                    setAmountTier2(dataTier2[0]?.amountQRO);
                    setRecipientPrincipalTier2FromBackend(dataTier2[0]?.updatedRecipientPrincipal);
                    setRecipientPrincipalTier2(dataTier2[0]?.updatedRecipientPrincipal);
                }

                setIsLoggedIn(true);
            }
        } catch (error) {
            showPopupError('Unable to login.');
        }
    }

    const handleSaveNFTs = async () => {
        const isValid = isValidPrincipal(recipientPrincipalNFTs);
        setIsValidRecipientPrincipalNFTs(isValid);

        if (!isValid) {
            showPopupWarning('Principal ID is not valid. Please provide a valid Principal ID to proceed.');
            setRecipientPrincipalNFTs(recipientPrincipalNFTsFromBackend);
            return false;
        }

        showPopupLoading();

        try {
            const hasAllowed = await window.ic?.plug?.requestConnect({
                whitelist: WHITELIST,
            });

            if (hasAllowed) {
                showPopupSaving();
                const actor = await window.ic.plug.createActor({ canisterId: QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID, interfaceFactory: SeedFundersBackendIDL });
                const result = await actor.updateNFTs(recipientPrincipalNFTs);
                if (result == 1) {
                    setRecipientPrincipalNFTsFromBackend(recipientPrincipalNFTs);
                }
                setShowPopup(false);
            }
        } catch (error) {
            showPopupError('Unable to save your updated principal ID.');
        }

        return true;
    }

    const handleSaveTier1 = async () => {
        const isValid = isValidPrincipal(recipientPrincipalTier1);
        setIsValidRecipientPrincipalTier1(isValid);

        if (!isValid) {
            showPopupWarning('Principal ID is not valid. Please provide a valid Principal ID to proceed.');
            setRecipientPrincipalTier1(recipientPrincipalTier1FromBackend);
            return false;
        }

        showPopupLoading();

        try {
            const hasAllowed = await window.ic?.plug?.requestConnect({
                whitelist: WHITELIST,
            });

            if (hasAllowed) {
                showPopupSaving();
                const actor = await window.ic.plug.createActor({ canisterId: QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID, interfaceFactory: SeedFundersBackendIDL });
                const result = await actor.updateTier1(recipientPrincipalTier1);
                if (result == 1) {
                    setRecipientPrincipalTier1FromBackend(recipientPrincipalTier1);
                }
                setShowPopup(false);
            }
        } catch (error) {
            showPopupError('Unable to save your updated principal ID.');
        }

        return true;
    }

    const handleSaveTier2 = async () => {
        const isValid = isValidPrincipal(recipientPrincipalTier2);
        setIsValidRecipientPrincipalTier2(isValid);

        if (!isValid) {
            showPopupWarning('Principal ID is not valid. Please provide a valid Principal ID to proceed.');
            setRecipientPrincipalTier2(recipientPrincipalTier2FromBackend);
            return false;
        }

        showPopupLoading();

        try {
            const hasAllowed = await window.ic?.plug?.requestConnect({
                whitelist: WHITELIST,
            });

            if (hasAllowed) {
                showPopupSaving();
                const actor = await window.ic.plug.createActor({ canisterId: QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID, interfaceFactory: SeedFundersBackendIDL });
                const result = await actor.updateTier2(recipientPrincipalTier2);
                if (result == 1) {
                    setRecipientPrincipalTier2FromBackend(recipientPrincipalTier2);
                }
                setShowPopup(false);
            }
        } catch (error) {
            showPopupError('Unable to save your updated principal ID.');
        }

        return true;
    }

    const handleEditNFTsClick = async () => {
        let valid = true;
        if (recipientPrincipalNFTsEdit) {
            valid = await handleSaveNFTs();
        }


        setRecipientPrincipalNFTsEdit(!recipientPrincipalNFTsEdit);
    };

    const handleEditTier1Click = async () => {
        let valid = true;
        if (recipientPrincipalTier1Edit) {
            valid = await handleSaveTier1();
        }

        // Toggle the edit mode
        setRecipientPrincipalTier1Edit(!recipientPrincipalTier1Edit);
    };

    const handleEditTier2Click = async () => {
        let valid = true;
        if (recipientPrincipalTier2Edit) {
            valid = await handleSaveTier2();
        }

        // Toggle the edit mode
        setRecipientPrincipalTier2Edit(!recipientPrincipalTier2Edit);
    };

    const handleChangeRecipientPrincipalIdNFTs = e => {
        setRecipientPrincipalNFTs(e.target.value);
    };

    const handleChangeRecipientPrincipalIdTier1 = e => {
        setRecipientPrincipalTier1(e.target.value);
    };

    const handleChangeRecipientPrincipalIdTier2 = e => {
        setRecipientPrincipalTier2(e.target.value);
    };

    const onClaim = () => {
        setShowPopup(false);
        setShowClaim(true);
    };

    const verifyConnectionWithPlug = async () => {
        let connected = await window.ic.plug.isConnected();
        if (!connected) {
            connected = await window.ic.plug.requestConnect({ whitelist: WHITELIST });
        }
        return connected;
    };

    const handleOnClamed = async () => {
        setShowPopup(true);
        setPopupType('loading');

        try {
            let hasAllowed = false;
            try {
                hasAllowed = await verifyConnectionWithPlug();
            } catch (error) {
                showPopupError('Connection to Plug failed.');
            }

            if (hasAllowed) {
                const actor = await window.ic.plug.createActor({ canisterId: QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID, interfaceFactory: SeedFundersBackendIDL });
                const dataNFTs = await actor.getNFTs();

                setShowPopup(false);

                if (dataNFTs?.length > 0 && dataNFTs[0]?.amountQRO) {
                    setAmountNFTs(dataNFTs[0]?.amountQRO);
                    setRecipientPrincipalNFTsFromBackend(dataNFTs[0]?.updatedRecipientPrincipal);
                    setRecipientPrincipalNFTs(dataNFTs[0]?.updatedRecipientPrincipal);
                }
            }
        } catch (error) {
            showPopupError('Unable to connect to plug wallet.');
        }
    }

    const onClaimed = () => {
        setShowPopup(false);
        setShowClaim(false);
        handleOnClamed();
    };

    return (
        <>
            <AssetHeader icon={principal} text={trimPrincipal(principal)} />

            {showPopup && <Popup type={popupType} message={popupMessage} setShowPopup={setShowPopup} onClaim={onClaim} onLogin={(e) => { e.preventDefault(); handleLoginWithPlug() }} />}

            {showClaim && <Claim amountQRO={amountToClaimQRO} onClaimed={onClaimed}/>}

            {!showClaim && <Container
                // maxWidth="xl"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    // justifyContent: 'center',
                    // minHeight: '100vh',
                    minHeight: '100vh',
                    height: '100%',
                    boxShadow: 3,
                    overflow: 'auto',
                    paddingTop: '2rem'
                }}
            >
                <img
                    src={QuerioLogo}
                    alt="Querio Logo"
                    style={{ width: matches ? '150px' : '75px', marginTop: '2rem', marginBottom: '2rem', cursor: 'pointer' }}
                    onClick={() => navigate("/")}
                />
                <Typography
                    sx={{
                        fontSize: '32px',
                        fontWeight: 500,
                        lineHeight: '44px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        marginBottom: '1.5rem'
                    }}
                >
                    Querio Asset Treasury
                </Typography>
                <Stack direction='row' justifyContent='center' alignItems='center' sx={{ marginBottom: '3rem' }}>
                    <img
                        src={ICPLogo}
                        alt='ICPLogo'
                        style={{
                            height: '2.5rem',
                            width: '2.5rem',
                            marginRight: '0.5rem'
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                        }}
                    >
                        {formatNumberWithSeparator(QUERIO_FUNDED + QUERIO_PRESALE)} ICP
                    </Typography>
                </Stack>
                <Stack
                    direction='row'
                    sx={{
                        marginRight: 'auto',
                        marginLeft: {
                            xs: '1rem',  // applies on extra-small devices (mobile)
                            sm: '5rem',  // applies on small devices and up (tablets, desktops)
                        },
                    }}
                    alignItems='center'
                >
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            marginRight: {
                                xs: '1rem',  // applies on extra-small devices (mobile)
                                sm: '3rem',  // applies on small devices and up (tablets, desktops)
                            }
                        }}
                    >
                        Funded neuron
                    </Typography>
                    <img
                        src={ICPLogo}
                        alt='ICPLogo'
                        style={{
                            height: '1.5rem',
                            width: '1.5rem',
                            marginRight: '0.3rem'
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            marginRight: '0.4rem'
                        }}
                    >
                        {formatNumberWithSeparator(QUERIO_FUNDED)} ICP
                    </Typography>
                    <IconButton onClick={handleNavigationFunded}>
                        <img
                            src={ExportLogo}
                            alt='ExportLogo'
                            style={{
                                paddingBottom: '0.3rem',
                                marginTop: 'auto',
                                marginBottom: 'auto'
                            }}
                        />
                    </IconButton>
                </Stack>
                <Stack
                    direction='row'
                    sx={{
                        marginRight: 'auto',
                        marginLeft: {
                            xs: '1rem',  // applies on extra-small devices (mobile)
                            sm: '5rem',  // applies on small devices and up (tablets, desktops)
                        },
                        marginBottom: '2.5rem'
                    }}
                    alignItems='center'
                >
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            marginRight: {
                                xs: '1rem',  // applies on extra-small devices (mobile)
                                sm: '3rem',  // applies on small devices and up (tablets, desktops)
                            }
                        }}
                    >
                        Presale neurons
                    </Typography>
                    <img
                        src={ICPLogo}
                        alt='ICPLogo'
                        style={{
                            height: '1.5rem',
                            width: '1.5rem',
                            marginRight: '0.3rem'
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            marginRight: '0.4rem'
                        }}
                    >
                        {formatNumberWithSeparator(QUERIO_PRESALE)} ICP
                    </Typography>
                    <IconButton onClick={handleNavigationPesale1}>
                        <img
                            src={ExportLogo}
                            alt='ExportLogo'
                            style={{
                                paddingBottom: '0.3rem',
                                marginTop: 'auto',
                                marginBottom: 'auto'
                            }}
                        />
                    </IconButton>
                    <IconButton onClick={handleNavigationPesale2}>
                        <img
                            src={ExportLogo}
                            alt='ExportLogo'
                            style={{
                                paddingBottom: '0.3rem',
                                marginTop: 'auto',
                                marginBottom: 'auto'
                            }}
                        />
                    </IconButton>
                </Stack>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        marginBottom: '4rem',
                        marginRight: 'auto',
                        marginLeft: 'auto'
                    }}
                >
                    {/* Button centered within a full-width Box */}
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Button
                            variant='contained'
                            onClick={() => {
                                if (!matches) {
                                    showPopupWarning('Access is available exclusively on laptops and desktops.');
                                    return;
                                }
                                setPopupType('login');
                                setShowPopup(true);
                            }}
                            sx={{
                                backgroundColor: '#FBB03B',
                                height: '2.5rem',
                                width: '12rem',
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: '24px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                textTransform: 'none',
                                '&: hover': {
                                    backgroundColor: '#FFBA4E',
                                },
                            }}
                        >
                            My QRO holdings
                        </Button>
                    </Box>
                </Stack>

                {isLoggedIn &&
                    <>
                        {(amountNFTs >= 0 || amountTier1 >= 0 || amountTier2 > 0) &&
                            <Stack sx={{ width: '100%', marginBottom: '2rem' }}>

                                <Divider sx={{ marginBottom: '3rem', width: '100%' }} />

                                <Typography
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: 500,
                                        lineHeight: '44px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                        marginBottom: '1.5rem'
                                    }}
                                >
                                    My Asset Treasury
                                </Typography>

                                <Stack direction='row' justifyContent='center' alignItems='center' sx={{ marginBottom: '3rem' }}>
                                    <img
                                        src={QuerioLogo}
                                        alt='QuerioLogo'
                                        style={{
                                            height: '1.7rem',
                                            width: '1.7rem',
                                            marginRight: '0.3rem'
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            lineHeight: '24px',
                                            letterSpacing: '0em',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {formatNumberWithSeparator(amountNFTs + amountTier1 + amountTier2)} QRO
                                    </Typography>
                                </Stack>
                            </Stack>}

                        {amountNFTs > 0 &&
                            <Stack
                                direction='row'
                                justifyContent='center'
                                alignItems='center'
                                sx={{
                                    marginBottom: '2rem',
                                    marginRight: 'auto',
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        marginRight: '3rem',
                                        marginLeft: {
                                            xs: '1rem',  // applies on extra-small devices (mobile)
                                            sm: '5rem',  // applies on small devices and up (tablets, desktops)
                                        },
                                    }}
                                >
                                    Querio NFTs
                                </Typography>
                                <img
                                    src={QuerioLogo}
                                    alt='QuerioLogo'
                                    style={{
                                        height: '1.3rem',
                                        width: '1.3rem',
                                        marginRight: '0.3rem'
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        marginRight: '0.5rem'
                                    }}
                                >
                                    {formatNumberWithSeparator(amountNFTs)} QRO
                                </Typography>

                                <TextField
                                    value={recipientPrincipalNFTs}
                                    label="NNS Principal ID"
                                    autoComplete="off"
                                    onChange={handleChangeRecipientPrincipalIdNFTs}
                                    disabled={!recipientPrincipalNFTsEdit}
                                    variant="outlined"
                                    sx={{
                                        width: '32rem',
                                        marginRight: '1rem',
                                        marginLeft: 'auto',
                                        '& .MuiOutlinedInput-root': {
                                            // Styles for the input field
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#99999980',
                                            },
                                            '& fieldset': {
                                                borderColor: '#99999980',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            // Styles for the label in all states
                                            color: '#999999',
                                        },
                                        '& .MuiInputLabel-outlined.Mui-focused': {
                                            // Specific styles for the focused label, if needed
                                            color: '#999999',
                                        },
                                        '& .MuiInputLabel-shrink': {
                                            // This ensures the label shrinks and moves up
                                            transform: 'translate(14px, -9px) scale(0.75)',
                                        },
                                    }}
                                />
                                {/*!isValidRecipientPrincipalNFTs && <ErrorIcon style={{ position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-50%)', color: 'red' }} />*/}
                                <Typography onClick={handleEditNFTsClick} sx={{ cursor: 'pointer', marginRight: recipientPrincipalNFTsEdit ? '4.45rem' : '5rem' }}>
                                    {recipientPrincipalNFTsEdit ? 'Save' : 'Edit'}
                                </Typography>
                            </Stack>}

                        {amountTier1 > 0 &&
                            <Stack
                                direction='row'
                                justifyContent='center'
                                alignItems='center'
                                sx={{
                                    marginBottom: '2rem',
                                    marginRight: 'auto',
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        marginRight: '3rem',
                                        marginLeft: {
                                            xs: '1rem',  // applies on extra-small devices (mobile)
                                            sm: '5rem',  // applies on small devices and up (tablets, desktops)
                                        },
                                    }}
                                >
                                    Querio Presale Tier1
                                </Typography>
                                <img
                                    src={QuerioLogo}
                                    alt='QuerioLogo'
                                    style={{
                                        height: '1.3rem',
                                        width: '1.3rem',
                                        marginRight: '0.3rem'
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        marginRight: '0.5rem'
                                    }}
                                >
                                    {formatNumberWithSeparator(amountTier1)} QRO
                                </Typography>

                                <TextField
                                    value={recipientPrincipalTier1}
                                    label="NNS Principal ID"
                                    autoComplete="off"
                                    onChange={handleChangeRecipientPrincipalIdTier1}
                                    disabled={!recipientPrincipalTier1Edit}
                                    variant="outlined"
                                    sx={{
                                        width: '32rem',
                                        marginRight: '1rem',
                                        marginLeft: 'auto',
                                        '& .MuiOutlinedInput-root': {
                                            // Styles for the input field
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#99999980',
                                            },
                                            '& fieldset': {
                                                borderColor: '#99999980',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            // Styles for the label in all states
                                            color: '#999999',
                                        },
                                        '& .MuiInputLabel-outlined.Mui-focused': {
                                            // Specific styles for the focused label, if needed
                                            color: '#999999',
                                        },
                                        '& .MuiInputLabel-shrink': {
                                            // This ensures the label shrinks and moves up
                                            transform: 'translate(14px, -9px) scale(0.75)',
                                        },
                                    }}
                                />
                                {/*!isValidRecipientPrincipalTier1 && <ErrorIcon style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: 'red' }} />*/}
                                <Typography onClick={handleEditTier1Click} sx={{ cursor: 'pointer', marginRight: recipientPrincipalTier1Edit ? '4.45rem' : '5rem' }}>
                                    {recipientPrincipalTier1Edit ? 'Save' : 'Edit'}
                                </Typography>
                            </Stack>}

                        {amountTier2 > 0 &&
                            <Stack
                                direction='row'
                                justifyContent='center'
                                alignItems='center'
                                sx={{
                                    marginBottom: '10rem',
                                    marginRight: 'auto',
                                    width: '100%'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        marginRight: '3rem',
                                        marginLeft: {
                                            xs: '1rem',  // applies on extra-small devices (mobile)
                                            sm: '5rem',  // applies on small devices and up (tablets, desktops)
                                        },
                                    }}
                                >
                                    Querio Presale Tier2
                                </Typography>
                                <img
                                    src={QuerioLogo}
                                    alt='QuerioLogo'
                                    style={{
                                        height: '1.3rem',
                                        width: '1.3rem',
                                        marginRight: '0.3rem'
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        marginRight: '0.5rem',
                                    }}
                                >
                                    {formatNumberWithSeparator(amountTier2)} QRO
                                </Typography>

                                <TextField
                                    value={recipientPrincipalTier2}
                                    label="NNS Principal ID"
                                    autoComplete="off"
                                    onChange={handleChangeRecipientPrincipalIdTier2}
                                    disabled={!recipientPrincipalTier2Edit}
                                    variant="outlined"
                                    sx={{
                                        width: '32rem',
                                        marginRight: '1rem',
                                        marginLeft: 'auto',
                                        '& .MuiOutlinedInput-root': {
                                            // Styles for the input field
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#99999980',
                                            },
                                            '& fieldset': {
                                                borderColor: '#99999980',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            // Styles for the label in all states
                                            color: '#999999',
                                        },
                                        '& .MuiInputLabel-outlined.Mui-focused': {
                                            // Specific styles for the focused label, if needed
                                            color: '#999999',
                                        },
                                        '& .MuiInputLabel-shrink': {
                                            // This ensures the label shrinks and moves up
                                            transform: 'translate(14px, -9px) scale(0.75)',
                                        },
                                    }}
                                />
                                {/*!isValidRecipientPrincipalTier2 && <ErrorIcon style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: 'red' }} />*/}
                                <Typography onClick={handleEditTier2Click} sx={{ cursor: 'pointer', marginRight: recipientPrincipalTier2Edit ? '4.45rem' : '5rem' }}>
                                    {recipientPrincipalTier2Edit ? 'Save' : 'Edit'}
                                </Typography>
                            </Stack>}
                    </>
                }
            </Container >}
        </>
    )
}
export default AssetTreasury;