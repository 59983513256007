import './App.css';
import Home from './components/Home';
import SearchPage from './components/SearchPage';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, useState, createContext } from "react";

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import giveTheme from './components/Theme';

import RedirectToHome from './components/RedirectToHome';

import AssetTreasury from './components/AssetTreasury';
import Bridge from './components/Bridge';
import PresaleLandingPage from './components/Presale';

export const AppContext = createContext({});

function App() {
  const [value, setValue] = useState('');
  const [mode, setMode] = useState(() => {
    const storedMode = localStorage.getItem('mode');
    return storedMode ? storedMode : 'light';
  });

  const theme = giveTheme(mode);

  useEffect(() => {
    localStorage.setItem('mode', mode);
  }, [mode]);

  const RouteProxy = () => {
    let query = new URLSearchParams(useLocation().search);
    let q = query.get("q");
    let p = query.get("presale");
    let a = query.get("treasury");
    let b = query.get("bridge");


    return (q ? <SearchPage /> : a ? <AssetTreasury/> : b ? <Bridge/> : p ? <PresaleLandingPage/> : <Home value={value} setValue={setValue} mode={mode} />);
  };

  return (
    <AppContext.Provider value={{ mode, setMode }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<RouteProxy />} />
            <Route path="*" element={<RedirectToHome />} />
          </Routes>
        </ThemeProvider>
      </StyledEngineProvider>
    </AppContext.Provider>
  );
}

export default App;
