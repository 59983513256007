import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Avatar, Box } from '@mui/material';

import { chains } from '../utils/Chains'
import { ReactComponent as FilterIcon } from '../assets/FilterIcon.svg';

const menuItems = chains;

const Filter = ({ onSelectedChain }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (index) => {
        setAnchorEl(null);
        onSelectedChain(index + 1);
    };

    return (
        <Box
            component='div'
            sx={{
                marginLeft: 'auto',
                marginRight: { xs: '1rem', sm: '1.4rem' }
            }}
        >
            <IconButton
                onClick={handleClick}
                sx={{
                    margin: { xl: '10px', lg: '10px', md: '10px', sm: 0 },
                    transform: 'translateY(-3px) translateX(2px)',
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    color: 'none',
                    padding: 0,

                }}
            >
                <FilterIcon
                    style={{ width: '1.5rem' }}
                />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                {menuItems.map((item, index) => (
                    <MenuItem key={index} onClick={() => handleMenuItemClick(index)}>
                        <Avatar
                            src={item.icon}
                            atl='logo'
                            sx={{
                                marginRight: '0.5rem',
                                height: '1.2rem',
                                width: '1.2rem',
                                backgroundColor: '#fff',
                                'img': {
                                    width: '0.95rem',
                                    height: '0.95rem',
                                    objectFit: 'fill'
                                },
                            }}
                        />
                        {item.title}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default Filter;