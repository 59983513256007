import fetch from "isomorphic-fetch";
import { HttpAgent } from "@dfinity/agent";
import { createActor as createEngineActor} from "./declarations/querio_se";
import { createActor as createWorkerActor} from "./declarations/querio_worker";
import { createActor as createFeedActor} from "./declarations/querio_feed";
import BigNumber from 'bignumber.js';

const { QUERIO_SE_HOST } = require ('./config');

export class ClientICP {
  constructor(SearchEngineCanisterId) {
    const agent = new HttpAgent({
        //identity: identity,
        host: QUERIO_SE_HOST,
        fetch,
    });
    this.agent = agent;
    this.workers = [];
    this.se_actor = createEngineActor(SearchEngineCanisterId, {agent});
  }

  async init() {
    if (this.workers.length > 0) {
      return;
    }

    try {
      const workers = await this.se_actor.get_workers();
      if (workers?.length > 0) {
        for (const w of workers) {
          this.workers.push(createWorkerActor(w, {agent : this.agent}))
        }
      }

      const feed = await this.se_actor.get_feed();

      if (feed?.length > 0) {
        this.feedWorker = createFeedActor(feed[0], {agent : this.agent});
      }
      
    } catch (e) {
      console.log(e);
    }
  }

  async refresh_feed_list() {
    try {
      this.feed_list = await this.feedWorker.get_list();
    } catch (error) {
      console.log(error);
    }
  }

  async feed(start, size) {
    let items = [];

    try {
      if (!this.feedWorker) {
        await this.init();
      }

      if (!this.feed_list) {
        await this.refresh_feed_list();
      }

      if (!this.feed_list?.length) {
        return;
      }

      let end = start + size;

      if (start >= this.feed_list?.length) {
        return;
      }

      if (end > this.feed_list?.length) {
        end = this.feed_list?.length;
      }

      /*for (let pos = start; pos < end; pos++ ) {
        const result = await this.feedWorker.get(this.feed_list[pos]);
        items.push(result);
      }*/
      
      items = await this.feedWorker.get_items(this.feed_list.slice(start, end));
    }
    catch (e) {
      console.log(e);
    }

    return items;
  }

  async search(q, page, chain) {
    if (this.workers.length == 0) {
      await this.init();
    }

    let items = [];
    let total = 0;
    let pages = 0;

    let start = Date.now();

    await Promise.all(this.workers.map(async (w) => {
      try {
        const currentResult = await w.search(q, page, chain);
        if (currentResult) {
          const currentPages = (new BigNumber(currentResult?.pages)).toNumber();
          items = [...items, ...currentResult?.items];
          total += (new BigNumber(currentResult?.total)).toNumber();

          if (currentPages > pages) {
            pages = currentPages;
          }
        }
      } catch (e) {
      }
    }));

    let duration = Date.now() - start;

    const result = {
      items: items,
      total: total,
      pages: pages,
      duration: duration,
    }

    return result;
  }

  async suggest(q, chain) {
    let result = undefined;
    let items = [];

    if (this.workers.length == 0) {
      await this.init();
    }

    await Promise.all(this.workers.map(async (w) => {
      try {
        const currentItems = await w.suggest(q, chain);

        if (currentItems?.length > 0) {
          items = [...items, ...currentItems];
        }
      } catch (e) {
      }
    }));

    if (items?.length > 0) {
      result = items;
    }

    return result;
  }
}