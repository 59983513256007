import { AppBar, Toolbar, Typography } from "@mui/material"
import Avatar from 'react-avatar';


function Header({ icon, text }) {
    return (
        <AppBar position="static" sx={{ background: 'transparent', boxShadow: '#00000040' }}>
            <Toolbar>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                    <Avatar round={true} size="40" name={icon} />
                    <Typography variant="subtitle1" sx={{ marginLeft: '8px', color: 'text.primary' }}>
                        {text}
                    </Typography>
                </div>
            </Toolbar>
        </AppBar>
    )
}
export default Header;