// react
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { useState, useEffect } from "react";

import { ClientICP } from '../client-icp';
import { useNavigate } from "react-router-dom";

//mui
import {
  Typography,
  InputAdornment,
  Autocomplete,
  Box,
} from "@mui/material"

// styled components
import {
  HomeCssTextField,
  Container,
  LogoSection,
  SearchSection,
  ButtonSection,
  NewsDivider,
} from './styledComponents';

// assets
import DarkLogo from '../assets/DarkLogo.svg';
import LightLogo from '../assets/LightLogo.svg';
import QuerioLogo from '../assets/QuerioLogo.svg'
import Search from '../assets/Search.svg'
import clearIcon from '../assets/clearIcon.svg'

//components
import { HeaderButtons } from './HeaderButtons';
import ShortcutButton from './ShortcutButton';
import NewsItem from './NewsItem';
//import { Footer } from './Footer';

import { featured } from './featured';

const { QUERIO_SE_CANISTER_ID } = require('../config');

const clientICPFeed = new ClientICP(QUERIO_SE_CANISTER_ID);

const Home = ({ value, setValue, mode }) => {
  const navigate = useNavigate();
  const [options, setOptions] = useState();
  const [inputValue, setInputValue] = useState('');
  const [newsItems, setNewsItems] = useState([]);
  const [feedOffset, setFeedOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [state, setState] = useState({
    loading: true,
    featured_list: featured,
  });

  const [feedSet, setFeedSet] = useState(new Set());

  useEffect(() => {
    const hasRun = localStorage.getItem('serviceWorkerCleanup');

    if (!hasRun && 'serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });

      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });

      // Set a flag in localStorage to indicate that the cleanup has run
      localStorage.setItem('serviceWorkerCleanup', 'true');
    }
  }, []);

  useEffect(() => {
    getFeedItems(feedOffset, 6).then((response) => {
      if (response?.length > 0) {
        setNewsItems([...newsItems, ...response]);
        setFeedOffset(newsItems.length + response.length);
      }
    });
  }, [page]);


  const addItemsToFeedSet = (items) => {
    setFeedSet(new Set(items));
  };


  const getFeedItems = async (offset, pageSize = 6) => {
    let pos = offset;
    let skipped = 0;
    let itemsSize = 0;
    let newFeedItems = [];
    let tmpSet = new Set([...feedSet]);

    try {
      do {
        const items = await clientICPFeed.feed(pos, pageSize);
        itemsSize = items?.length;

        for (const item of items) {
          if (!tmpSet.has(item.key)) {
            newFeedItems.push(item);
            tmpSet.add(item.key);
          } else {
            skipped++;
          }

          pos++;
        }
      } while ((pos < offset + pageSize + skipped - 1) && (itemsSize == pageSize));

      addItemsToFeedSet([...tmpSet]);
    }
    catch (e) {
      console.log(e);
    }


    return newFeedItems;
  }

  const changeValue = (e) => {
    //setValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    const isMobileOrTablet = window.innerWidth <= 768;
    if (isMobileOrTablet && (event.key === 'Enter' || event.keyCode === 13)) {
      event.preventDefault();
      event.target.blur(); // This will remove focus from the input and hide the keyboard
    }
  };

  const onChange = (event, newValue) => {
    handleKeyDown(event);
    navigate(`?q=${encodeURIComponent(newValue)}`);
  }

  const onInputChange = async (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue?.length > 2) {
      const clientICP = new ClientICP(QUERIO_SE_CANISTER_ID);
      clientICP.suggest(newInputValue, 0).then((response) => {
        let suggest_items = [];
        if (response?.length > 0) {
          for (let i = 0; i < response.length; i++) {
            const words = newInputValue.split(' ');
            const firstWord = words[0];
            let item = response[i];

            let alphanumericStr = item.replace(/[^a-zA-Z0-9\s]/g, '');
            let suggest_item = firstWord + ' ' + alphanumericStr;

            suggest_items.push(suggest_item.replace(/\s{2,}/g, ' ').trim());
          }
        }

        setOptions([newInputValue, ...suggest_items]);
      });
    }
  }

  // Adjust the breakpoint to your preferred value for mobile and tablet devices
  const isMobileOrTablet = (window.innerWidth <= 768);

  return (
    <Container>
      <HeaderButtons />
      <LogoSection>
        <img
          src={mode === 'light' ? LightLogo : DarkLogo}
          alt='Querio'
        />
      </LogoSection>
      <SearchSection>
        <Autocomplete
          freeSolo
          disableClearable
          id="combo-box-demo"
          //onKeyDown={keyPress}
          options={options ? options : []}
          /*clearIcon={
            <img
              src={clearIcon}
              alt=' '
              style={{
                width: '1rem',
                marginBottom: '0.1rem',
              }}
              onClick={(e) => setOptions([])}
            />
          }*/
          getOptionLabel={(option) => option || value}
          onChange={onChange}
          inputValue={inputValue}
          onInputChange={onInputChange}
          ListboxProps={{
            sx: {
              backgroundColor: 'searchBox.main',
            }
          }}
          renderOption={(props, option,) => (
            <li {...props}>
              <img
                src={Search}
                alt=' '
                style={{
                  marginRight: '1rem',
                  width: '1.8rem',
                }}
              />
              <Typography color='#000'>
                {option}
              </Typography>
            </li>
          )}
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            "& .MuiOutlinedInput-root": {
              paddingRight: "0.5rem!important",
            },
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
          renderInput={(params) =>
            <HomeCssTextField
              {...params}
              placeholder="Search on Web3"
              variant="outlined"
              onChange={changeValue}
              autoFocus={!isMobileOrTablet}
              sx={{ input: { color: '#000' } }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={QuerioLogo}
                      alt=' '
                      style={{
                        marginLeft: '0.5rem',
                        width: '2.2rem',
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <React.Fragment>
                    <img
                      src={Search}
                      alt=' '
                      onClick={(e) => {
                        onChange(e, inputValue);
                        setOptions([]);
                      }}
                      style={{
                        marginRight: '0.3rem',
                        width: '1.8rem',
                        cursor: 'pointer'
                      }}
                    />
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                sx: {
                  backgroundColor: 'searchBox.main',
                  "& img": {
                    order: 3,
                  },
                  "& > div.MuiAutocomplete-endAdornment": {
                    position: "relative",
                    order: 2,
                  },
                }
              }}
            />
          }
        />
      </SearchSection>

      <ButtonSection>
        {state?.featured_list?.map((item, index) => (
          <ShortcutButton key={index} logo={item.icon} title={item.title} url={item.url} />
        ))}
      </ButtonSection>

      <div className='newsFeed'>
        <Box
          component='div'
          sx={{
            color: 'newsTitleColor.main',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 600,
            fontFamily: 'helvetica',
          }}>
          Crypto News
        </Box>

        {newsItems?.map((item, index) => (
          <React.Fragment key={item.key}>
            <NewsItem key={item.key} item={item} />
            {index >= newsItems?.length - 12 && (
              <Waypoint
                onEnter={() => {
                  setPage(page + 1);
                }}
              />
            )}
            <NewsDivider />
          </React.Fragment>
        ))}
      </div>
      {/*<Footer />*/}
    </Container>

  );
};

export default Home;
