import { caseInsensitiveStartsWith } from './caseInsensitiveStartsWith.js';
import { chainMap } from './Chains';


export function trimChain(query) {
    const trimSubstring = (str, substr) => str.substring(substr.length);
    let chain_q = query;
    let chain_id = 0;

    for (const chain of Object.keys(chainMap)) {
        if (caseInsensitiveStartsWith(chain_q, `${chain}:`)) {
            chain_id = chainMap[chain];
            chain_q = trimSubstring(chain_q, `${chain}:`);
        }
    }

    return { query: chain_q, chain: chain_id };
}
