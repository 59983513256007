import {
    Box,
} from "@mui/material";

import styled from '@emotion/styled';


export const SearchSection = styled(Box)({
    padding: '1rem',
    // paddingTop: '4rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '1rem',
    alignItems: 'center',
});