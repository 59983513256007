import { Stack } from "@mui/material";

// import { styled } from "@mui/material/styles";

import styled from "@emotion/styled";

export const ContentStack = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
        width: '30rem',
    },
    [theme.breakpoints.up('md')]: {
        width: '45rem',
    },
    [theme.breakpoints.up('lg')]: {
        width: '45rem',
    },
    [theme.breakpoints.up('xl')]: {
        width: '45rem',
    },
}));