import React from "react";
import { Waypoint } from 'react-waypoint';
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import { ClientICP } from '../client-icp';

import {
    InputAdornment,
    Stack,
    Typography,
    Autocomplete,
    Button,
    Box
} from "@mui/material";

import useMediaQuery from '@mui/material/useMediaQuery';

import {
    CssTextField,
    SearchContainer,
    ResultsContainer,
    StyledTabs,
    StyledTab,
    CustomDivider,
} from "./styledComponents";

// components
import { HeaderButtons } from "./HeaderButtons";
import LinkComponent from "./LinkComponent";
import Filter from "./Filter";

// assets
import QuerioLogo from '../assets/QuerioLogo.svg';
import Search from '../assets/Search.svg';
import SearchTab from '../assets/SearchTab.svg';
import clearIcon from '../assets/clearIcon.svg';

// utils
import { formatDuration } from "../utils/formatDuration";
import { chains, chainMap, reverseChainMap } from "../utils/Chains";
import { trimChain } from '../utils/trimChain';

const { QUERIO_SE_CANISTER_ID } = require('../config');

function SearchPage() {
    let query = new URLSearchParams(useLocation().search);
    let q = query.get("q");
    if (!q) {
        q = '';
    }

    const t = query.get("type");

    let tab = (t !== 'all') && chainMap[t] ? 1 : 0;
    let chainParam = t !== 'all' ? chainMap[t] || 0 : 0;

    const [value, setValue] = useState(q);
    const [options, setOptions] = useState();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [selectedChain, setSelectedChain] = useState(chainParam);
    const [inputValue, setInputValue] = useState(q);
    const [tabValue, setTabValue] = useState(tab);
    const matches = useMediaQuery('(min-width:600px)');

    const [state, setState] = useState({
        loading: true,
        value: value,
        search_results: {
            items: [],
            total: 0,
            pages: 0,
            duration: 0,
        }
    });

    useEffect(() => {
        window.addEventListener('popstate', onPopState);
        const script = document.createElement('script');
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        document.body.appendChild(script);

        let chain = 0;

        if (!value) {
            const options = {
                pathname: '/',
            };
            navigate(options, { replace: true });
        } else {
            const clientICP = new ClientICP(QUERIO_SE_CANISTER_ID);
            let start = Date.now();
            let chain_q = state.value;
            const tc = trimChain(chain_q);

            if (tabValue != 0) {
                chain = selectedChain;
            }

            if (tc.chain && tabValue == 0) {
                chain_q = tc.query;
                chain = tc.chain;
            }

            clientICP.search(chain_q, page, chain).then((response) => {
                if (response?.items.length === 1) {
                    if (response?.items[0]?.url === "") {
                        response.items = [];
                        response.total = 0;
                        response.pages = 0;
                    }
                }
                let duration = Date.now() - start;
                setState({
                    loading: false,
                    value: state.value,
                    search_results: {
                        items: [...state.search_results.items, ...response?.items],
                        total: response?.total,
                        pages: response?.pages,
                        duration: duration,
                    },
                });

            });
        }

        return () => {
            window.removeEventListener('popstate', onPopState);
        };
    }, [tabValue, state.value, page, setState, selectedChain]);

    const handleKeyDown = (event) => {
        const isMobileOrTablet = window.innerWidth <= 768;
        if (isMobileOrTablet && (event.key === 'Enter' || event.keyCode === 13)) {
            event.preventDefault();
            event.target.blur(); // This will remove focus from the input and hide the keyboard
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);

        const type = (newValue === 1) ? reverseChainMap[selectedChain] : 'all';
        const options = {
            pathname: '/',
            search: `?q=${encodeURIComponent(state.value)}&type=${type}`,
        };

        navigate(options);
        setOptions([]);
        setPage(1);
        setState({
            loading: true,
            value: value,
            search_results: {
                items: [],
                total: 0,
                pages: 0,
                duration: 0,
            }
        })
    };

    const onPopState = () => {
        window.location.reload();
    };

    const onChange = (event, newValue) => {
        if (newValue && (newValue !== state.value)) {
            setValue(newValue);
            const type = (tabValue === 1) ? reverseChainMap[selectedChain] : 'all';
            const options = {
                pathname: '/',
                search: `?q=${encodeURIComponent(newValue)}&type=${type}`,
            };
            navigate(options);

            setState({
                loading: true,
                value: newValue,
                search_results: {
                    items: [],
                    total: 0,
                    pages: 0,
                    duration: 0,
                },
            });
        }
        setPage(1);
    }

    const onInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue?.length > 2) {
            const clientICP = new ClientICP(QUERIO_SE_CANISTER_ID);
            let chain = 0;
            let chain_q = newInputValue;
            let chain_keyword = '';

            const tc = trimChain(chain_q);

            if (tabValue != 0) {
                chain = selectedChain;
            }

            if (tc.chain && tabValue == 0) {
                chain_q = tc.query;
                chain = tc.chain;

                if (chain != selectedChain) {
                    chain_keyword = reverseChainMap[chain];
                };
            }

            clientICP.suggest(chain_q, chain).then((response) => {
                let suggest_items = [];
                if (response?.length > 0) {
                    for (let i = 0; i < response.length; i++) {
                        const words = chain_q.split(' ');
                        let firstWord = words[0];
                        let item = response[i];

                        if (chain_keyword) {
                            firstWord = chain_keyword + ' ' + firstWord;
                        }

                        let alphanumericStr = item.replace(/[^a-zA-Z0-9\s]/g, '');
                        let suggest_item = firstWord + ' ' + alphanumericStr;

                        suggest_items.push(suggest_item.replace(/\s{2,}/g, ' ').trim());
                    }
                }

                setOptions([...new Set([newInputValue, ...suggest_items])]);
            });
        }
    }

    const changeValue = (e) => {
        setValue(e.target.value);
    }

    const handleChange = (event, value) => {
        setPage(value);
        window.scrollTo(0, 0);
    };

    const onSelectedChain = (chain) => {
        if (chain == selectedChain) {
            return;
        }

        setSelectedChain(chain);

        const type = reverseChainMap[chain];

        const options = {
            pathname: '/',
            search: `?q=${encodeURIComponent(state.value)}&type=${type}`,
        };
        navigate(options);
        setOptions([]);
        setPage(1);
        setState({
            loading: true,
            value: value,
            search_results: {
                items: [],
                total: 0,
                pages: 0,
                duration: 0,
            }
        });

        setTabValue(1);
    }

    return (
        <>
            <SearchContainer>
                <Stack direction='row' sx={{ width: 'inherit' }}>
                    <Autocomplete
                        freeSolo
                        disableClearable
                        /*clearIcon={
                            <img
                                src={clearIcon}
                                alt=''
                                style={{
                                    width: '1rem',
                                    marginBottom: '0.1rem',
                                }}
                                onClick={(e) => setOptions([])}
                            />
                        }*/
                        id="combo-box-demo"
                        options={options ? options : []}
                        getOptionLabel={(option) => option || value}
                        onChange={onChange}
                        inputValue={inputValue}
                        onInputChange={onInputChange}
                        onKeyDown={handleKeyDown}
                        ListboxProps={{
                            sx: {
                                backgroundColor: 'searchBox.main',
                            }
                        }}
                        renderOption={(props, option,) => (
                            <li {...props}>
                                <img
                                    src={option.icon || Search}
                                    alt=''
                                    style={{
                                        marginLeft: '0.2rem',
                                        marginRight: '0.6rem'
                                    }}
                                />
                                <Typography color='#000'>
                                    {option || value}
                                </Typography>
                            </li>
                        )}
                        onOpen={(e) => { }}
                        onClose={(e) => { }}
                        sx={{
                            paddingTop: '1rem',
                            paddingBottom: '0.5rem',
                            "& .MuiOutlinedInput-root": {
                                paddingRight: "0.5rem!important",
                            },
                            width: '100%'
                        }}
                        renderInput={(params) =>
                            <CssTextField
                                {...params}
                                placeholder="Search on Web3"
                                variant="outlined"
                                onChange={changeValue}
                                sx={{ input: { color: '#000' } }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            onClick={() => navigate("/")}
                                        >
                                            <img
                                                src={QuerioLogo}
                                                alt=''
                                                style={{
                                                    marginLeft: '0.5rem',
                                                    width: '2.2rem',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <React.Fragment>
                                            <img
                                                src={Search}
                                                alt=''
                                                onClick={(e) => {
                                                    onChange(e, inputValue);
                                                    setOptions([]);
                                                }}
                                                style={{
                                                    marginRight: '0.3rem',
                                                    width: '1.8rem',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                    sx: {
                                        backgroundColor: 'searchBox.main',
                                        "& img": {
                                            order: 3,
                                        },
                                        "& > div.MuiAutocomplete-endAdornment": {
                                            position: "relative",
                                            order: 2,
                                        },
                                    }
                                }}
                            />
                        }
                    />
                    <HeaderButtons />
                </Stack>
                <Box
                    sx={{
                        display: 'flex',
                        // justifyContent: 'space-between',
                        alignItems: 'center',
                        width: { xl: '46rem', lg: '46rem', md: '46rem', sm: '29.5rem', xs: '100%' },
                    }}
                >
                    <StyledTabs
                        value={tabValue}
                        onChange={handleTabChange}
                    >
                        <StyledTab
                            icon={
                                <img
                                    src={SearchTab}
                                    alt=''
                                    style={{
                                        marginRight: '0.3rem',
                                        width: '1.5rem',
                                        cursor: 'pointer'
                                    }}
                                />
                            }
                            onClick={(e) => {
                                onChange(e, inputValue);
                                setOptions([]);
                            }}
                            iconPosition="start"
                            label="Web3"
                        />
                        {
                            selectedChain && <StyledTab
                                icon={
                                    <img
                                        src={chains[selectedChain - 1]?.icon}
                                        alt=''
                                        style={{
                                            marginRight: '0.3rem',
                                            width: '1.4rem',
                                            cursor: 'pointer'
                                        }}
                                    />
                                }
                                onClick={(e) => {
                                    onChange(e, inputValue);
                                    setOptions([]);
                                }}
                                iconPosition="start"
                                label={chains[selectedChain - 1]?.title}
                            />
                        }
                    </StyledTabs>
                    <Filter onSelectedChain={onSelectedChain} />
                </Box>
            </SearchContainer>

            <CustomDivider />

            <ResultsContainer>
                <Typography sx={{
                    color: 'tabsColors.main',
                    fontFamily: 'sans-serif',
                    fontSize: '14px',
                    lineHeight: '0px',
                    paddingTop: '0rem',
                    letterSpacing: '0.2px',
                    paddingBottom: '30px',
                }}>
                    {!state.loading ? `About ${state?.search_results?.total} results ${formatDuration(state?.search_results?.duration)}` : ''}
                </Typography>
                {matches ? (
                    <>
                        <Stack direction='column'>
                            {state?.search_results?.items?.map((item, index) => (
                                <LinkComponent key={index} uid={`${page}-${index}`} q={q.toLowerCase()} type={reverseChainMap[selectedChain]} item={item} />
                            ))}
                        </Stack >
                        {((state?.search_results?.pages === 0) && !state.loading) && <div>Your search - <b>{state.value}</b> - did not match any documents.</div>}
                        {state?.search_results?.pages > 1 &&
                            (page < state?.search_results?.pages) && <Button
                                hidden={(page >= state?.search_results?.pages)}
                                sx={{
                                    marginTop: '1rem',
                                    marginBottom: '2rem',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    display: 'block',
                                    margin: '20px auto',
                                    padding: '10px 20px',
                                    fontSize: '16px',
                                    color: '#5f6368',
                                    backgroundColor: '#f8f9fa',
                                    border: '1px solid #f8f9fa',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    outline: 'none',
                                    transition: 'all 0.3s ease',
                                    textTransform: 'none',
                                    '&:hover': {
                                        boxShadow: '0 1px 1px rgba(0,0,0,0.1)',
                                        border: '1px solid #c6c6c6',
                                    }
                                }}
                                onClick={() => {
                                    if (page < state?.search_results?.pages) {
                                        setPage(page + 1);
                                    }
                                }}>More results</Button>}
                    </>
                ) : (
                    <>
                        <Stack direction='column'>
                            {state?.search_results?.items?.map((item, index) => (
                                <React.Fragment key={index}>
                                    <LinkComponent q={q.toLowerCase()} type={reverseChainMap[selectedChain]} item={item} />
                                    {index === state?.search_results?.items?.length - 3 && (
                                        <Waypoint
                                            onEnter={() => {
                                                if (page < state?.search_results?.pages) {
                                                    setPage(page + 1);
                                                }
                                            }}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </Stack >
                        {((state?.search_results?.pages === 0) && !state.loading) && <div>Your search - <b>{state.value}</b> - did not match any documents.</div>}
                    </>
                )}
            </ResultsContainer>
        </>
    );
}

export default SearchPage;