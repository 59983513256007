import {
    Box,
} from "@mui/material";

import styled from '@emotion/styled';


export const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    // height: '100vh',  // Set to 100vh to take the full viewport height
    // justifyContent: 'center',  // Center elements vertically
    // alignItems: 'center',  // Center elements horizontally

    [theme.breakpoints.up('xs')]: {
        marginTop: '8rem',
    },
    [theme.breakpoints.up('sm')]: {
        marginTop: '10rem',
    },
    [theme.breakpoints.up('md')]: {
        marginTop: '10rem',
    },
}));