import {
    Divider,
} from "@mui/material";

import styled from '@emotion/styled';

export const CustomDivider = styled(Divider)(({ theme }) => ({
    backgroundColor: theme.palette.backgroundColor.main,
    opacity: 0.5,
}));

export const NewsDivider = styled(Divider)(({ theme }) => ({
    backgroundColor: theme.palette.divider,
}));