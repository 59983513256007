import React from 'react';
import styled from 'styled-components';
import PlugLogo from '../assets/PlugLogo.png';
import MetaMaskLogo from '../assets/MetaMask.svg';
import { Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { Button } from '@mui/material';

// Function to construct the block explorer URL
const getBlockExplorerUrl = (hash) => {
    return `https://etherscan.io/tx/${hash}`;
};

function Popup({ onLogin, type, message, setShowPopup, onClaim, onSuccesfulClaim }) {
    return (
        <>
            {type === 'loading' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Loading</h2>
                        <Wallet>
                            <p>Please wait.</p>
                            <BeatLoader />
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'waitfortransaction' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Please wait</h2>
                        <Wallet>
                            <p>Please wait while your transaction is being confirmed. This process may take anywhere from a couple of minutes to a few hours. Thank you for your patience!</p>
                            <BeatLoader />
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'saving' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Saving</h2>
                        <Wallet>
                            <p>Please wait.</p>
                            <BeatLoader />
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'login' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Login and Authorize</h2>
                        <Wallet>
                            <p>Please login and authorize access to your Plug wallet to continue.</p>
                            <img src={PlugLogo} alt="Plug" />
                        </Wallet>
                        <ActionButtonLink onClick={onLogin}>Login</ActionButtonLink>
                        <Button
                            sx={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                border: '1px solid #FFD700',
                                backgroundColor: 'transparent',
                                color: '#FFD700',
                                borderRadius: '4px',
                                textDecoration: 'none',
                                transition: 'background-color 0.3s, color 0.3s',
                                cursor: 'pointer',

                                '&:hover': {
                                    backgroundColor: '#FFD900',
                                    color: ' #000',
                                }
                            }}
                            onClick={() => setShowPopup(false)}
                        >
                            Dismiss
                        </Button>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'waitforlogin' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Login and Authorize</h2>
                        <Wallet>
                            <p>
                                Press Allow on your Plug Wallet, if the Plug wallet popup is not shown, go ahead and
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    window.location.reload();
                                }}> refresh the page</a>.
                            </p>
                            <img src={PlugLogo} alt="Plug" />
                            <BeatLoader />
                        </Wallet>
                        <ActionButtonLink onClick={onLogin}>Login</ActionButtonLink>
                        <Button
                            sx={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                border: '1px solid #FFD700',
                                backgroundColor: 'transparent',
                                color: '#FFD700',
                                borderRadius: '4px',
                                textDecoration: 'none',
                                transition: 'background-color 0.3s, color 0.3s',
                                cursor: 'pointer',

                                '&:hover': {
                                    backgroundColor: '#FFD900',
                                    color: ' #000',
                                }
                            }}
                            onClick={() => setShowPopup(false)}
                        >
                            Dismiss
                        </Button>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'waitforapprove' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Approve</h2>
                        <Wallet>
                            <p>
                                Press Approve on your Plug Wallet, if the Plug wallet popup is not shown within 2 minutes, go ahead and
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    window.location.reload();
                                }}> refresh the page</a>.
                            </p>
                            <img src={PlugLogo} alt="Plug" />
                            <BeatLoader />
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'waitforconfirm' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Confirm</h2>
                        <Wallet>
                            <p>
                                Press Confirm on your MetaMask Wallet, if the MetaMask wallet popup is not shown within 2 minutes, go ahead and
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    window.location.reload();
                                }}> refresh the page</a>.
                            </p>
                            <img src={MetaMaskLogo} alt="MetaMask" />
                            <BeatLoader />
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'install' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Connect Wallet</h2>
                        <Wallet>
                            <img src={PlugLogo} alt="Plug" />
                            <h3>Plug</h3>
                            <p>
                                You'll need to install Plug to continue. Once you have it installed, go ahead and
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    window.location.reload();
                                }}> refresh the page</a>.
                            </p>
                            <ActionButtonLink onClick={() => window.open('https://plugwallet.ooo/')}>Install Plug</ActionButtonLink>
                            <Button
                                sx={{
                                    display: 'inline-block',
                                    padding: '10px 20px',
                                    border: '1px solid #FFD700',
                                    backgroundColor: 'transparent',
                                    color: '#FFD700',
                                    borderRadius: '4px',
                                    textDecoration: 'none',
                                    transition: 'background-color 0.3s, color 0.3s',
                                    cursor: 'pointer',

                                    '&:hover': {
                                        backgroundColor: '#FFD900',
                                        color: ' #000',
                                    }
                                }}
                                onClick={() => setShowPopup(false)}
                            >
                                Back
                            </Button>
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'info' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Info</h2>
                        <Wallet>
                            <p>{message}</p>
                            <Button
                                sx={{
                                    display: 'inline-block',
                                    padding: '10px 20px',
                                    border: '1px solid #FFD700',
                                    backgroundColor: 'transparent',
                                    color: '#FFD700',
                                    borderRadius: '4px',
                                    textDecoration: 'none',
                                    transition: 'background-color 0.3s, color 0.3s',
                                    cursor: 'pointer',

                                    '&:hover': {
                                        backgroundColor: '#FFD900',
                                        color: ' #000',
                                    }
                                }}
                                onClick={() => setShowPopup(false)}
                            >
                                Back
                            </Button>
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'claim' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Claim</h2>
                        <Wallet>
                            <p>{message}</p>
                        </Wallet>
                        <Button
                            sx={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                border: '1px solid #FFD700',
                                marginRight: '2rem',
                                backgroundColor: '#FFD900',
                                color: ' #000',
                                borderRadius: '4px',
                                textDecoration: 'none',
                                transition: 'background-color 0.3s, color 0.3s',
                                cursor: 'pointer',

                                '&:hover': {
                                    backgroundColor: '#FFD700',
                                    color: ' #000',
                                }
                            }}
                            onClick={onClaim}
                        >
                            Claim
                        </Button>
                        <Button
                            sx={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                border: '1px solid #FFD700',
                                backgroundColor: 'transparent',
                                color: '#FFD700',
                                borderRadius: '4px',
                                textDecoration: 'none',
                                transition: 'background-color 0.3s, color 0.3s',
                                cursor: 'pointer',

                                '&:hover': {
                                    backgroundColor: '#FFD900',
                                    color: ' #000',
                                }
                            }}
                            onClick={() => setShowPopup(false)}
                        >
                            Close
                        </Button>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'successful' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Successful</h2>
                        <Wallet>
                            <p>{message}</p>
                        </Wallet>
                        <Button
                            sx={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                border: '1px solid #FFD700',
                                backgroundColor: 'transparent',
                                color: '#FFD700',
                                borderRadius: '4px',
                                textDecoration: 'none',
                                transition: 'background-color 0.3s, color 0.3s',
                                cursor: 'pointer',

                                '&:hover': {
                                    backgroundColor: '#FFD900',
                                    color: ' #000',
                                }
                            }}
                            onClick={() => setShowPopup(false)}
                        >
                            Ok
                        </Button>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'bridge-successful' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Successful</h2>
                        <Wallet>
                            <p>
                                QRO tokens successfully converted. 
                                <a
                                    href={getBlockExplorerUrl(message)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >View on block explorer</a>.
                            </p>
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'errorwithreload' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Error</h2>
                        <Wallet>
                            <p>{message}</p>
                            <Button
                                sx={{
                                    display: 'inline-block',
                                    padding: '10px 20px',
                                    border: '1px solid #FFD700',
                                    backgroundColor: 'transparent',
                                    color: '#FFD700',
                                    borderRadius: '4px',
                                    textDecoration: 'none',
                                    transition: 'background-color 0.3s, color 0.3s',
                                    cursor: 'pointer',

                                    '&:hover': {
                                        backgroundColor: '#FFD900',
                                        color: ' #000',
                                    }
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.reload();
                                }}
                            >
                                Reload
                            </Button>
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'support' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Support</h2>
                        <Wallet>
                            <p>{message}</p>
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'error' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Error</h2>
                        <Wallet>
                            <p>{message}</p>
                            <Button
                                sx={{
                                    display: 'inline-block',
                                    padding: '10px 20px',
                                    border: '1px solid #FFD700',
                                    backgroundColor: 'transparent',
                                    color: '#FFD700',
                                    borderRadius: '4px',
                                    textDecoration: 'none',
                                    transition: 'background-color 0.3s, color 0.3s',
                                    cursor: 'pointer',

                                    '&:hover': {
                                        backgroundColor: '#FFD900',
                                        color: ' #000',
                                    }
                                }}
                                onClick={() => setShowPopup(false)}
                            >
                                Back
                            </Button>
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
            {type === 'warning' && (
                <PopupContainer>
                    <CustomPopup>
                        <h2>Notice</h2>
                        <Wallet>
                            <p>{message}</p>
                            <Button
                                sx={{
                                    display: 'inline-block',
                                    padding: '10px 20px',
                                    border: '1px solid #FFD700',
                                    backgroundColor: 'transparent',
                                    color: '#FFD700',
                                    borderRadius: '4px',
                                    textDecoration: 'none',
                                    transition: 'background-color 0.3s, color 0.3s',
                                    cursor: 'pointer',

                                    '&:hover': {
                                        backgroundColor: '#FFD900',
                                        color: ' #000',
                                    }
                                }}
                                onClick={() => setShowPopup(false)}
                            >
                                Ok
                            </Button>
                        </Wallet>
                    </CustomPopup>
                </PopupContainer>
            )}
        </>
    );
}

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`;

const CustomPopup = styled.div`
  background-color: #F5F5F5;
  padding: 40px;
  border-radius: 10px;
  // Set the width to 50% of the viewport width and height to 60% of the viewport height
  width: 50vw;
  //height: 25vh;

  max-width: 600px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

const Wallet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
  }

  h3 {
    margin-top: 10px;
  }

  p {
    text-align: center;
    margin: 20px 0;
  }
`;

const ActionButtonLink = styled(Link)`
  margin-right: 6rem;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #FFD700;
  background-color: #FFD700;
  color: #000;
  border-radius: 4px;
  text-decoration: none;
    transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #FFD700;
    color: #000;
  }
`;

const DismissButtonLink = styled(Link)`  
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #FFD700;
    background-color: transparent;
    color: #FFD700;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;

    &:hover {
        background-color: #FFD900;
        color: #000;
    }
`;


export default Popup;
