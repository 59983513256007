// mui
import {
    Stack,
    Avatar,
    Box,
    Link,
} from "@mui/material";

import { useState, useEffect } from "react";

// styled components
import { ContentStack } from "./styledComponents";

// components
import BoldSubstrings from "./BoldSubstrings";

// assets
import altLogo from '../assets/altLogo.svg';

// utils
import { trimExtensionType } from '../utils/trimExtensionType';
import { trimChain } from '../utils/trimChain';
import { chains, reverseChainMap } from '../utils/Chains';
import BigNumber from 'bignumber.js';


export default function LinkComponent({ uid, q, item }) {
    let query;
    try {
        query = trimExtensionType(q)?.query;
        query = trimChain(query).query;
    } catch (error) {

    }
    if (!query) {
        query = q;
    }

    const { url, title, heading, icon, icon_data, snippet, chain } = item;
    const alphanumeric = query?.replace(/[^a-zA-Z0-9\s]/g, '');
    const words = alphanumeric?.split(' ');
    const chainValue = (new BigNumber(chain)).toNumber();

    let chain_url = `?q=${encodeURIComponent(q)}&type=${reverseChainMap[chainValue]}`;


    let trimmedHeading = heading;
    if (heading.startsWith(title + '/')) {
        trimmedHeading = heading.substr(title.length + 1);
    }

    let avatarLogo = altLogo;
    if (icon_data) {
        avatarLogo = `${icon_data}`;
    } else if (icon) {
        avatarLogo = icon;
    }

    const [imageUrl, setImageUrl] = useState(avatarLogo);
    const [avatarKey, setAvatarKey] = useState(uid + Date.now().toString());

    useEffect(() => {
        setImageUrl(avatarLogo);
    }, [avatarLogo]);


    const handleImageError = () => {
        setImageUrl(altLogo);
        setAvatarKey(uid + Date.now().toString()); //re-render
    };

    return (
        <Box
            key={uid}
            sx={{
                paddingY: '1rem'
            }}
        >
            <Stack direction="row">
                <Avatar
                    key={avatarKey}
                    src={imageUrl}
                    alt='avatarLogo'
                    onError={handleImageError}
                    sx={{
                        backgroundColor: 'linkColors.logo',
                        marginRight: {
                            xs: '0.5rem',
                            sm: '1rem',
                            md: '1rem',
                            lg: '1rem',
                            xl: '1rem',
                        },
                        marginTop: {
                            xs: '0.4rem',
                            sm: '0.2rem',
                            md: '0.2rem',
                            lg: '0.2rem',
                            xl: '0.2rem',
                        },
                        width: {
                            xs: '2rem',
                            sm: '2.5rem',
                            md: '2.5rem',
                            lg: '2.5rem',
                            xl: '2.5rem',
                        },
                        height: {
                            xs: '2rem',
                            sm: '2.5rem',
                            md: '2.5rem',
                            lg: '2.5rem',
                            xl: '2.5rem',
                        },
                    }}
                />
                <Stack direction="column">
                    {/* Result name */}
                    <Link
                        sx={{
                            typography: 'subtitle1',
                            color: 'linkColors.main',
                            fontFamily: 'sans-serif',
                            letterSpacing: '0.5px',
                            lineHeight: '26px',
                            overflowWrap: 'anywhere',
                        }}
                        underline="none"
                        href={url}
                    >
                        {title}
                    </Link>

                    {/* Result - entire URL */}
                    <Link
                        sx={{
                            typography: 'subtitle2',
                            color: 'linkColors.secondary',
                            fontFamily: 'sans-serif',
                            fontSize: '13px',
                            lineHeight: '14px',
                            letterSpacing: '0.2px',
                            overflowWrap: 'anywhere',
                        }}
                        underline="none"
                        href={url}
                    >
                        {url}
                    </Link>
                </Stack>
            </Stack>

            <ContentStack direction="column">
                {/* Content link */}
                <Link
                    sx={{
                        color: 'blue.main',
                        typography: 'h6',
                        fontFamily: 'sans-serif',
                        fontSize: '21px',
                        fontWeight: '400',
                        letterSpacing: '-0.5px',
                        lineHeight: '36px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    underline="hover"
                    href={url}
                >
                    {trimmedHeading ? trimmedHeading : title}
                </Link>

                {/* Content snippet */}
                <BoldSubstrings text={snippet.replace(/-{2,}/g, '')} boldSubstrings={words} />
                <Link
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '1.5rem',
                        width: '6.5rem',
                        fontSize: '15px',
                        marginTop: '0.5rem',
                        textTransform: 'none',
                        color: '#006691',
                        fontWeight: 500,
                        fontFamily: 'sans-serif',
                    }}
                    underline="hover"
                    href={chain_url}
                >
                    <Avatar
                        src={chains[chainValue - 1].icon}
                        atl='icp'
                        sx={{
                            marginRight: '0.5rem',
                            height: '1.2rem',
                            width: '1.2rem'
                        }}
                    />
                    {chains[chainValue - 1].title}
                </Link>
            </ContentStack>
        </Box>
    );
}