/*global BigInt*/

import React from 'react';
import { Button, Container, Typography, Box } from "@mui/material";

import "../App.css";

// import QuerioText from '../assets/Querio.svg';
import QuerioLogo from '../assets/QuerioLogo.svg'

function PresaleLandingPage() {
    return (
        <>
            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: `calc(100vh - 0.5rem)`,  // Adjusting height for top and bottom margins
                    textAlign: 'center',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    padding: '0 0.5rem'  // Horizontal padding ensures content doesn't touch the sides on smaller screens
                }}
            >
                <img src={QuerioLogo} alt="Querio Logo" style={{ width: '150px', marginBottom: '2rem', marginTop: '3rem' }} />
                <Typography variant="h4" style={{ marginBottom: '2rem' }}>
                    Querio Presale Ended
                </Typography>
                <Box
                    sx={{
                        width: '60%',
                        marginBottom: '1rem',
                        padding: '0 0.5rem'
                    }}>
                    <Typography paragraph gutterBottom style={{ fontWeight: 'bold' }}>
                        Raised: 40'612 ICP
                    </Typography>
                    <Typography paragraph gutterBottom>
                        <br />Participants: 134<br />
                        Sold: 4'061'200 QRO<br />
                        Price: 1 QRO = 0.01 ICP
                    </Typography>
                </Box>
                <Typography paragraph>
                    <strong>Participation Tiers:</strong><br />
                    - <strong>Tier 1:</strong> 100 - 3,000 ICP, no KYC<br />
                    - <strong>Tier 2:</strong> 3,001 - 10,000 ICP, KYC needed.
                </Typography>
                <Typography paragraph>
                    Limit: each participant is limited to one purchase per tier.<br />
                    <strong>Presale Ended on November 8, 2023</strong><br />
                </Typography>
                <Typography paragraph>
                    Questions? <a href="https://discord.gg/Jvb8Xmzgdv">Discord</a>, <a href="https://oc.app/community/qbzct-jaaaa-aaaar-au2gq-cai/?ref=jviq4-waaaa-aaaar-aqq7a-cai">OpenChat</a>, support@querio.io
                </Typography>
                <div style={{ display: 'flex', gap: '30px', marginTop: '2rem', marginBottom: '3rem' }}>
                    <Button variant="contained" disabled={true} sx={{ backgroundColor: '#57B660', width: '120px', whiteSpace: 'nowrap', textAlign: 'center' }} >
                        <div>
                            <span style={{ fontWeight: 'bold' }}>Tier 1</span><br />
                        </div>
                    </Button>
                    <Button variant="contained" disabled={true} sx={{ backgroundColor: '#57B660', width: '120px', whiteSpace: 'nowrap', textAlign: 'center' }} >
                        <div>
                            <span style={{ fontWeight: 'bold' }}>Tier 2</span><br />
                        </div>
                    </Button>
                </div>
            </Container>
        </>
    );
}

export default PresaleLandingPage;