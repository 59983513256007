export const idlFactory = ({ IDL }) => {
  const TransactionItem = IDL.Record({
    'to' : IDL.Text,
    'status' : IDL.Nat8,
    'ethTransferTx' : IDL.Text,
    'from' : IDL.Text,
    'icrcBlockIndex' : IDL.Nat,
    'timestamp' : IDL.Int,
    'amount' : IDL.Nat,
  });
  return IDL.Service({
    'getLogs' : IDL.Func([IDL.Nat], [IDL.Vec(TransactionItem)], []),
    'getPendingTransactions' : IDL.Func(
        [IDL.Nat],
        [IDL.Vec(TransactionItem)],
        [],
      ),
    'getTransaction' : IDL.Func([], [IDL.Opt(TransactionItem)], []),
    'saveCompletedTx' : IDL.Func([IDL.Text], [IDL.Bool], []),
    'saveTransferTx' : IDL.Func([IDL.Text, IDL.Text], [IDL.Bool], []),
    'setPaused' : IDL.Func([IDL.Bool], [], []),
    'transfer' : IDL.Func([IDL.Nat, IDL.Text, IDL.Text], [IDL.Nat8], []),
  });
};
export const init = ({ IDL }) => { return []; };
