import TimeAgo from "../utils/TimeAgo";
import { useMediaQuery, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { useState } from "react";

import altLogo from '../assets/altLogo.svg';

function truncateLine(line, maxLength, addDots = true) {
    if (line.length > maxLength) {
        const words = line.split(' ');
        let truncatedLine = '';

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            if ((truncatedLine + ' ' + word).length <= maxLength) {
                truncatedLine += (truncatedLine === '' ? '' : ' ') + word;
            } else {
                break;
            }
        }

        if (addDots) {
            line = truncatedLine + ' ...';
        } else {
            line = truncatedLine + ' ...';
        }
    }

    return line;
}

const NewsItem = ({ item }) => {
    const theme = useTheme();

    const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isSmallTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmallMobile = useMediaQuery('(max-width:412px)');
    const isExtraSmallMobile = useMediaQuery('(max-width:350px)');

    const [imageUrl, setImageUrl] = useState(item.icon_data);

    const handleImageError = () => {
        setImageUrl(altLogo);
    };

    return (
        <a
            href={item.url}
            style={{
                textDecoration: 'inherit',
                color: 'inherit',
            }}
        >
            <div className='newsItem'>
                {item.article_image_data && (
                    <img
                        src={item.article_image_data}
                        alt={item.title}
                        className='newsImage'
                    />
                )}
                <div className='newsContent'>
                    <small style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                        <div
                            // news source
                            style={{
                                marginRight: '0.5rem',
                                color: '#848484',
                                marginBottom: '2px',
                                whiteSpace: 'nowrap',
                                fontSize: isSmallMobile ? '12px' : (isMobile ? '13px' : '14px'),
                                letterSpacing: (isTabletOrDesktop || isSmallTablet) ? '0.14px' : '0px',
                            }}>
                            {`${item.source}`}
                        </div>

                        <img
                            src={imageUrl}
                            alt={item.source}
                            onError={handleImageError}
                            style={{
                                width: '12px',
                                height: '12px',
                                marginBottom: '4px',
                            }}
                        />

                        {(!isTabletOrDesktop && !isSmallTablet && !isExtraSmallMobile) && <>
                            <Box
                                // render a black circle
                                component='div'
                                sx={{
                                    marginLeft: '0.5rem',
                                    marginRight: '0.5rem',
                                    fontSize: '5px',
                                    marginBottom: '2px',
                                    color: 'newsDetails.main',
                                }}
                            >
                                {`\u2B24`}
                            </Box>

                            <Box
                                // time detail on mobile/tablet
                                component='div'
                                sx={{
                                    color: 'newsDetails.main',
                                    marginBottom: '2px',
                                    fontSize: isSmallMobile ? '12px' : (isMobile ? '13px' : '14px'),
                                }}
                            >
                                {isSmallMobile ? `${truncateLine(`${TimeAgo(item.pub_date)}`, 12)}` : `${TimeAgo(item.pub_date)}`}
                            </Box>
                        </>}
                    </small>

                    <Box
                        // news title
                        component='div'
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: isTabletOrDesktop ? '18px' : (isExtraSmallMobile ? '14px' : '16px'),
                            lineHeight: isMobile ? '1.25' : '1.4',
                            fontWeight: 600,
                            color: 'newsTitleColor.main',
                            fontFamily: 'helvetica',
                        }}
                    >
                        {(isTabletOrDesktop || isSmallTablet) ?
                            (item.article_title.length > 165 ? `${truncateLine(item.article_title, 165)}` : item.article_title) :
                            (isExtraSmallMobile ? (item.article_title.length > 72 ? `${truncateLine(item.article_title, 72)}` : item.article_title) :
                                (isSmallMobile ? (item.article_title.length > 100 ? `${truncateLine(item.article_title, 100)}` : item.article_title) :
                                    (item.article_title.length > 120 ? `${truncateLine(item.article_title, 120)}` : item.article_title)))}
                    </Box>
                    {(isTabletOrDesktop || isSmallTablet) && <small>
                        <Box
                            // time detail on desktop
                            component='div'
                            sx={{
                                color: 'newsDetails.main',
                                marginTop: '3px', //typography: 'subtitle1',
                                letterSpacing: '0.14px',
                            }}
                        >
                            {`${TimeAgo(item.pub_date)}`}
                        </Box>
                    </small>}
                </div>
            </div>
        </a>
    )
}


export default NewsItem