import React, { useEffect, useContext, useState } from 'react';
import {
    Link,
    // Dialog,
    // DialogContent,
    // DialogContentText,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon
} from '@mui/material'

import xLogo from '../assets/XLogo.svg';
import xLogoDark from '../assets/XLogoDark.svg';
import LightBtn from '../assets/LightBtn.svg';
import DarkBtn from '../assets/DarkBtn.svg';
import DrawerLogo from '../assets/DrawerLogo.svg';
import DrawerLogoBlack from '../assets/DrawerLogoBlack.svg';
import DrawerTop from '../assets/DrawerTop.svg';
import DrawerTopBlack from '../assets/DrawerTopBlack.svg';
import Tokenomics from '../assets/Tokenomics.svg';
import TokenomicsBlack from '../assets/TokenomicsBlack.svg';
import PresaleBlack from '../assets/PresaleBlack.svg';
import PresaleWhite from '../assets/PresaleWhite.svg';
import Team from '../assets/Team.svg';
import TeamBlack from '../assets/TeamBlack.svg';
import Whitepaper from '../assets/Github.svg';
import WhitepaperBlack from '../assets/GithubBlack.svg';
import RoadmapLogo from '../assets/RoadmapLogo.svg';
import RoadmapLogoDark from '../assets/RoadmapLogoDark.svg';
import DiscordLogo from '../assets/DiscordLogo.svg';
import DiscordLogoBlack from '../assets/DiscordLogoBlack.svg';
import OpenChatLogo from '../assets/featured/openchat.png';
import TelegramLogo from '../assets/TelegramLogo.svg';

import { styled } from '@mui/system';

import { CustomDivider } from "./styledComponents";

import { AppContext } from '../App';


const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1.3rem;
  top: 1rem;
  width:100%;
  height:20px;
`;


export function HeaderButtons() {
    const { mode, setMode } = useContext(AppContext);
    const [open, setOpen] = useState(false);
    // const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [drawerWidth, setDrawerWidth] = useState(getDrawerWidth());

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        document.body.appendChild(script);

        function handleResize() {
            setDrawerWidth(getDrawerWidth());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function getDrawerWidth() {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            return '280px'; // mobile or tablet
        } else {
            return '300px'; // default
        }
    }

    return (
        <ButtonContainer>
            <IconButton
                sx={{
                    padding: 0,
                    marginRight: 'auto',
                    marginLeft: '2.5rem'
                }}
                onClick={() => setMode((prevState) => {
                    if (prevState === 'light') {
                        return 'dark';
                    }
                    return 'light';
                })}
            >
                <img
                    src={mode === 'light' ? DarkBtn : LightBtn}
                    style={{
                        width: '1.3rem',
                        height: '1.3rem',
                    }}
                    alt='switchTheme'
                />
            </IconButton>

            <Link
                underline='always'
                href="/?bridge=1"
                sx={{
                    color: `${mode === 'light' ? '#464646' : '#fff'}`,
                    fontWeight: 600,
                    fontFamily: 'sans-serif',
                    fontSize: '15px',
                    letterSpacing: '0.3px',
                    marginX: '1rem',
                    marginTop: '0rem',
                }}
            >
                Bridge to ERC-20
            </Link>

            <IconButton onClick={handleDrawerOpen} sx={{
                width: '1.3rem',
                height: '1.3rem',
                paddingLeft: '1rem',
            }}>
                <img src={mode === 'light' ? DrawerLogoBlack : DrawerLogo} alt='menu' />
            </IconButton>
            <Drawer
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                anchor="right"
                open={open}
                onClose={handleDrawerClose}
            >
                <List style={{ display: 'flex', flexDirection: 'column', height: '100%' }} onClick={handleDrawerClose}>
                    {/* Logo at the top */}
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '1rem',
                            marginBottom: '1rem'
                        }}>
                        <img src={mode === 'light' ? DrawerTopBlack : DrawerTop} alt="Logo" style={{ width: '100px', height: 'auto' }} />
                    </ListItem>

                    {/* Divider */}
                    <CustomDivider />

                    <ListItem disablePadding>
                        <ListItemButton href="https://github.com/QuerioDAO/whitepaper/blob/1c814de6776363d937cd074ac4328db73c90f95e/querio-whitepaper.pdf">
                            <ListItemIcon>
                                <img src={mode === 'light' ? WhitepaperBlack : Whitepaper} alt='x' style={{ width: '1.5rem' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Whitepaper'
                                sx={{
                                    color: 'theme.palette.main.primary',
                                    fontWeight: 500,
                                    fontFamily: 'sans-serif',
                                    fontSize: '15px',
                                    letterSpacing: '0.3px',
                                }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton href="https://github.com/QuerioDAO/whitepaper/blob/75d773300a8ade7c1a8d818007b38e2aafd39e3e/querio-tokenomics.pdf">
                            <ListItemIcon>
                                <img src={mode === 'light' ? TokenomicsBlack : Tokenomics} alt='x' style={{ width: '1.5rem' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Tokenomics'
                                sx={{
                                    color: 'theme.palette.main.primary',
                                    fontWeight: 500,
                                    fontFamily: 'sans-serif',
                                    fontSize: '15px',
                                    letterSpacing: '0.3px',
                                }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton href="https://github.com/QuerioDAO/whitepaper/blob/75d773300a8ade7c1a8d818007b38e2aafd39e3e/querio-roadmap.pdf">
                            <ListItemIcon>
                                <img src={mode === 'light' ? RoadmapLogo : RoadmapLogoDark} alt='x' style={{ width: '1.5rem' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Roadmap'
                                sx={{
                                    color: 'theme.palette.main.primary',
                                    fontWeight: 500,
                                    fontFamily: 'sans-serif',
                                    fontSize: '15px',
                                    letterSpacing: '0.3px',
                                }}
                            />
                        </ListItemButton>
                    </ListItem>

                    {/*<ListItem disablePadding>
                        <ListItemButton href="https://github.com/QuerioDAO/querio-sns/blob/main/sns_proposal_summary.md">
                            <ListItemIcon>
                                <img src={mode === 'light' ? ProposalBlack : Proposal} alt='x' style={{ width: '1.5rem' }} />
                            </ListItemIcon>
                            <ListItemText 
                                primary='SNS proposal summary' 
                                sx={{
                                    color: 'theme.palette.main.primary',
                                    fontWeight: 500,
                                    fontFamily: 'sans-serif',
                                    fontSize: '15px',
                                    letterSpacing: '0.3px',
                                }}
                            />
                        </ListItemButton>
                    </ListItem>*/}

                    {/* <ListItem disablePadding>
                        <ListItemButton href="/?treasury=1">
                            <ListItemIcon>
                                <img src={mode === 'light' ? TreasuryBlack : Treasury} alt='x' style={{ width: '1.5rem' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Treasury'
                                sx={{
                                    color: 'theme.palette.main.primary',
                                    fontWeight: 500,
                                    fontFamily: 'sans-serif',
                                    fontSize: '15px',
                                    letterSpacing: '0.3px',
                                }}
                            />
                        </ListItemButton>
                    </ListItem> */}

                    <ListItem disablePadding>
                        <ListItemButton href="/?presale=1">
                            <ListItemIcon>
                                <img src={mode === 'light' ? PresaleBlack : PresaleWhite} alt='x' style={{ width: '1.8rem'}} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Presale'
                                sx={{
                                    color: 'theme.palette.main.primary',
                                    fontWeight: 500,
                                    fontFamily: 'sans-serif',
                                    fontSize: '15px',
                                    letterSpacing: '0.3px',
                                }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton href="https://crosschainlabs.tech/meet-the-team">
                            <ListItemIcon>
                                <img src={mode === 'light' ? TeamBlack : Team} alt='x' style={{ width: '1.6rem' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Meet the team'
                                sx={{
                                    color: 'theme.palette.main.primary',
                                    fontWeight: 500,
                                    fontFamily: 'sans-serif',
                                    fontSize: '15px',
                                    letterSpacing: '0.3px',
                                }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <div style={{ flexGrow: 1 }}></div>

                    <ListItem style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ListItemButton
                            href="https://twitter.com/querio_io?ref_src=twsrc%5Etfw"
                            sx={{
                                paddingX: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                width: '5rem'
                            }}
                        >
                            <img
                                src={mode === 'light' ? xLogo : xLogoDark}
                                alt='x'
                                style={{
                                    width: '1.4rem',
                                    height: '1.65rem',
                                }}
                            />
                        </ListItemButton>

                        <ListItemButton
                            href="https://oc.app/community/qbzct-jaaaa-aaaar-au2gq-cai/?ref=jviq4-waaaa-aaaar-aqq7a-cai"
                            sx={{
                                paddingX: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                width: '5rem'
                            }}
                        >
                            <img src={OpenChatLogo} alt='x' style={{ width: '1.65rem', height: '1.65rem' }} />
                        </ListItemButton>

                        <ListItemButton
                            href="https://discord.com/invite/Jvb8Xmzgdv"
                            sx={{
                                paddingX: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                width: '5rem'
                            }}
                        >
                            <img src={mode === 'light' ? DiscordLogoBlack : DiscordLogo} alt='x' style={{ width: '1.85rem', height: '1.65rem' }} />
                        </ListItemButton>
                        <ListItemButton
                            href="https://t.me/+VXpWvtRUSyU0MmFk"
                            sx={{
                                paddingX: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                width: '5rem'
                            }}
                        >
                            <img src={TelegramLogo} alt='x' style={{ width: '1.85rem', height: '1.85rem' }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>

            {/* <Link
                underline='none'
                href="/?treasury=1"
                sx={{
                    marginRight: '1rem',
                    color: `${mode === 'light' ? '#464646' : '#fff'}`,
                    fontWeight: 600,
                    fontFamily: 'sans-serif',
                    fontSize: '15px',
                    letterSpacing: '0.3px',
                }}
            >
                Treasury
            </Link>
            <Link
                underline='none'
                href="https://presale.querio.io"
                sx={{
                    color: `${mode === 'light' ? '#464646' : '#fff'}`,
                    fontWeight: 500,
                    fontFamily: 'sans-serif',
                    fontSize: '15px',
                    letterSpacing: '0.3px',
                }}
            >
                Presale
            </Link> 
            <Link
                underline='none'
                href="https://querio.notion.site/Querio-Whitepaper-093dca931e374657a5ae7c42a8f9da16?pvs=4"
                sx={{
                    color: `${mode === 'light' ? '#464646' : '#fff'}`,
                    fontWeight: 500,
                    fontFamily: 'sans-serif',
                    fontSize: '15px',
                    letterSpacing: '0.3px',
                    marginX: '1rem',
                    marginTop: '0rem'
                }}
            >
                Whitepaper
            </Link>

            <a
                href="https://twitter.com/querio_io?ref_src=twsrc%5Etfw"
                style={{
                    display: 'inline-block',
                    background: `url(${mode === 'light' ? xLogo : xLogoDark})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    width: '1.2rem',
                    height: '1.2rem',
                    marginRight: '1rem'
                }}
            />

            <a
                href="https://linktr.ee/querio"
                style={{
                    display: 'inline-block',
                    background: `url(${mode === 'light' ? LinkTreeLogo : LinkTreeLogoDark})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    width: '1.3rem',
                    height: '1.3rem',
                    paddingLeft: '1rem',
                    //marginTop: '-0.05rem'
                }}
            /> */}

            {/* <IconButton
                onClick={handleClickOpen}
                sx={{
                    padding: 0,
                    marginX: '0.5rem',
                    marginY: 'auto'
                }}
            >
                <img
                    src={mode === 'light' ? HelpBtnLight : HelpBtnDark}
                    alt='help'
                    style={{
                        width: '1.8rem',
                        height: '1.8rem',
                    }}
                />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Placeholder help text
                    </DialogContentText>
                </DialogContent>
            </Dialog> */}

        </ButtonContainer>
    );
}
