import OpenChat from "../assets/featured/openchat.png";
import Funded from "../assets/featured/funded.png";
import OpenSea from "../assets/featured/OpenSea.svg";
import Distrikt from "../assets/featured/distrikt.svg";
import DSCVR from "../assets/featured/DSCVR.png";
import Modclub from "../assets/featured/modclub.png";
import Verse from "../assets/featured/Verse.png";
import BitcoinNews from "../assets/featured/BitcoinNews.png";
import MetaMask from "../assets/featured/MetaMask.svg";
import ICPSwap from "../assets/featured/ICPSwap.svg";
import Dmail from "../assets/featured/Dmail.png";

export const featured = [
    { url: "https://oc.app", title: "Open Chat", icon: OpenChat },
    { url: "https://verse.bitcoin.com/", title: "Verse", icon: Verse },
    { url: "https://dmail.ai/", title: "Dmail", icon: Dmail },
    { url: "https://metamask.io/", title: "MetaMask", icon: MetaMask },
    { url: "https://app.icpswap.com/swap", title: "ICPSwap", icon: ICPSwap },
    { url: "https://modclub.ai/", title: "Modclub", icon: Modclub }
];