const EXTENSIONS = ['.js', '.jsx', '.ts', '.tsx', /*'.json',*/ '.mo', '.rs', '.md'];

export function trimExtensionType(query) {
    let result = {
        query: query,
    }
    const trimSubstring = (str, substr) => str.startsWith(substr) ? str.substring(substr.length) : str;
    for (const ext of EXTENSIONS) {
        const key = trimSubstring(ext, '.') + ':';
        if (query.startsWith(key)) {
            result.query = trimSubstring(query, key)?.trim();
            result.ext = ext;
            result.keyword = key;
            break;
        }
    }

    return result;
}
