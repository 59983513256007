export const idlFactory = ({ IDL }) => {
  const Participant = IDL.Record({
    'participantPrincipal' : IDL.Text,
    'amountQRO' : IDL.Nat32,
    'recipientPrincipal' : IDL.Text,
    'updatedRecipientPrincipal' : IDL.Text,
  });
  const SeedFunders = IDL.Service({
    'addTier1' : IDL.Func([IDL.Text, IDL.Nat32, IDL.Text], [IDL.Nat32], []),
    'addTier2' : IDL.Func([IDL.Text, IDL.Nat32, IDL.Text], [IDL.Nat32], []),
    'claimNFTs' : IDL.Func([IDL.Text, IDL.Bool], [IDL.Nat32], []),
    'downloadNFTs' : IDL.Func([], [IDL.Vec(Participant)], ['query']),
    'downloadTier1' : IDL.Func([], [IDL.Vec(Participant)], ['query']),
    'downloadTier2' : IDL.Func([], [IDL.Vec(Participant)], ['query']),
    'getNFTs' : IDL.Func([], [IDL.Opt(Participant)], ['query']),
    'getTier1' : IDL.Func([], [IDL.Opt(Participant)], ['query']),
    'getTier2' : IDL.Func([], [IDL.Opt(Participant)], ['query']),
    'hasUnclaimedNFTs' : IDL.Func([], [IDL.Opt(Participant)], ['query']),
    'removeNFTs' : IDL.Func([IDL.Text], [], []),
    'removeTier1' : IDL.Func([IDL.Text], [], []),
    'removeTier2' : IDL.Func([IDL.Text], [], []),
    'updateNFTs' : IDL.Func([IDL.Text], [IDL.Nat32], []),
    'updateTier1' : IDL.Func([IDL.Text], [IDL.Nat32], []),
    'updateTier2' : IDL.Func([IDL.Text], [IDL.Nat32], []),
  });
  return SeedFunders;
};
export const init = ({ IDL }) => { return []; };
