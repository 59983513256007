import {
    Box,
} from "@mui/material";

import styled from '@emotion/styled';

export const ButtonSection = styled(Box)(({ theme }) => ({
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    height: { xl: '150px', md: '300px' },
    // paddingTop: '0.5rem',
    // top: '53px',
    zIndex: 0,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '1rem',
    'img': {
        width: '3.5rem',
    },
    [theme.breakpoints.up('xs')]: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        'img': {
            width: '2.6rem',
        },
    },
    [theme.breakpoints.up('sm')]: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        'img': {
            width: '3.5rem',
        },
    },
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        'img': {
            width: '3.5rem',
        },
    },
}));