/*global BigInt*/

import Web3 from 'web3';
import { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography, Container, Stack, useMediaQuery, Divider, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Principal } from '@dfinity/principal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemButton from '@mui/material/ListItemButton';

import { qroABI } from '../abi/qro.js';
import Popup from './Popup';
import AssetHeader from './AssetsHeader';
import { AppContext } from '../App';
import QuerioLogo from '../assets/QuerioLogo.svg';
import DiscordLogo from '../assets/DiscordLogo.svg';
import DiscordLogoBlack from '../assets/DiscordLogoBlack.svg';
import OpenChatLogo from '../assets/featured/openchat.png';
import TelegramLogo from '../assets/TelegramLogo.svg';

import { idlFactory as QROIDL } from '../idls/QRO.did';
import { idlFactory as QROBridge } from '../idls/QROBridge.did';

const ICP = BigInt(100000000);

const {
    QRO_TOKEN_CANISTER_ID,
    QRO_BRIDGE_CANISTER_ID,
    QRO_TOKEN,
    QRO_BRIDGE,
    FEE,
    MINIMUM_QRO_BALANCE
} = require('../config');

const RESULT = {
    statusTransferSuccessful: 1,
    statusErrorTransferFailed: 2,
    statusErrorDuringTransfer: 3,
    statusErrorPendingTransactionExists: 4,
    statusErrorPendingLimitExceeded: 5,
    statusICRCtoERC20Successful: 6,
    statusErrorInvalidDestinationAddress: 7,
    statusErrorInvalidAmount: 8,
    statusPaused: 9,
};

const WHITELIST = [QRO_TOKEN_CANISTER_ID, QRO_BRIDGE_CANISTER_ID];

function trimPrincipal(principal) {
    const firstDashIndex = principal.indexOf('-');
    const lastDashIndex = principal.lastIndexOf('-');

    return `${principal.substring(0, firstDashIndex + 1)}...${principal.substring(lastDashIndex)}`;
}
function verifyResult(result) {
    // Check if 'Ok' key exists in the object
    if (result.hasOwnProperty('Ok')) {
        // Check if the value associated with 'Ok' is a BigInt
        if (typeof result.Ok === 'bigint') {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function convertICRCAmount(amount) {
    const decimalPlaces = 4;
    const scaledResult = BigInt(amount) / BigInt(10000);

    const resultString = scaledResult.toString();
    const integerPart = resultString.slice(0, -decimalPlaces) || "0";
    const decimalPart = resultString.slice(-decimalPlaces).padStart(decimalPlaces, "0");

    // Format the final output to show it as a decimal number
    return `${integerPart}.${decimalPart}`;
}

/*
function ethAddressToBase64(hexString) {
    // Remove the '0x' prefix if it exists
    hexString = hexString.startsWith('0x') ? hexString.substring(2) : hexString;

    // Create a Buffer from the hex string and then convert to Base64
    return Buffer.from(hexString, 'hex').toString('base64');
}
*/

/*
// Reverse for ethAddressToBase64 memo
function base64ToEthAddress(base64String) {
    return '0x' + Buffer.from(base64String, 'base64').toString('hex');
}
*/

/*
function base64ToEthAddress(base64String) {
    // Decode the base64 string to a binary string
    const binaryString = atob(base64String);

    // Convert the binary string to a hex string
    let hexString = '';
    for (let i = 0; i < binaryString.length; i++) {
        const hex = binaryString.charCodeAt(i).toString(16);
        hexString += (hex.length === 1 ? '0' : '') + hex;
    }

    // Return the hex string prefixed with '0x'
    return '0x' + hexString;
}
*/

function ethAddressToBase64(hexString) {
    // Remove the '0x' prefix if it exists
    hexString = hexString.startsWith('0x') ? hexString.substring(2) : hexString;

    // Convert the hex string to a byte array
    const byteArray = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < byteArray.length; i++) {
        byteArray[i] = parseInt(hexString.substr(i * 2, 2), 16);
    }

    // Convert the byte array to a binary string
    const binaryString = byteArray.reduce((acc, byte) => acc + String.fromCharCode(byte), '');

    // Convert the binary string to a base64 string
    return btoa(binaryString);
}
function base64ToUtf8(base64String) {
    try {
        const binaryString = atob(base64String);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return new TextDecoder('utf-8').decode(bytes);
    } catch (e) {
        console.error("Failed to convert base64 to UTF-8", e);
        return null;
    }
}

function Bridge() {
    const [isLoggedInWithPlug, setIsLoggedInWithPlug] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [isLoggedInWithMetamask, setIsLoggedInWithMetamask] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [ethAddress, setEthAddress] = useState('');
    const [currentNetwork, setCurrentNetwork] = useState('');
    const [principal, setPrincipal] = useState('');
    const [balance, setBalance] = useState(0);
    const [havePendingTransaction, setHavePendingTransaction] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [iAgree, setIAgree] = useState(false);

    const { mode, setMode } = useContext(AppContext);

    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();

    //TODO Remove
    /////////////////////////////////////////////////////
    const [password, setPassword] = useState('');
    const [accessGranted, setAccessGranted] = useState(false);
    const correctHash = "937e8d5fbb48bd4949536cd65b8d35c426b80d2f830c5c308e2cdec422ae2244"; // SHA-256 hash of password

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const verifyPassword = async () => {
        const encoder = new TextEncoder();
        const data = encoder.encode(password);
        const hash = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hash)); // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
        if (hashHex === correctHash) {
            setAccessGranted(true);
        } else {
            alert('Incorrect Password!');
        }
    };
    /////////////////////////////////////////////////////

    if (!matches) {
        showPopupWarning('Access is available exclusively on laptops and desktops.');
    }

    const showPopupWaitForTransaction = () => {
        setShowPopup(true);
        setPopupType('waitfortransaction');
    }

    const showPopupError = (message) => {
        setShowPopup(true);
        setPopupType('error');
        setPopupMessage(message);
    }

    const showPopupErrorWithReload = (message) => {
        setShowPopup(true);
        setPopupType('errorwithreload');
        setPopupMessage(message);
    }

    const showPopupWarning = (message) => {
        setShowPopup(true);
        setPopupType('warning');
        setPopupMessage(message);
    }

    const fetchPendingTx = async () => {
        try {
            const bridgeActor = await window.ic.plug.createActor({ canisterId: QRO_BRIDGE_CANISTER_ID, interfaceFactory: QROBridge });
            const pendingTransaction = await bridgeActor.getTransaction();

            if (pendingTransaction?.length > 0) {
                setBalance(convertICRCAmount(pendingTransaction[0].amount));
                setEthAddress(pendingTransaction[0].to);
                setIAgree(true);

                if (pendingTransaction[0].ethTransferTx) {
                    clearInterval(intervalId);
                    setIntervalId(null);

                    setPopupMessage(pendingTransaction[0].ethTransferTx);
                    setPopupType('bridge-successful');
                    setShowPopup(true);
                    setCompleted(true);
                    localStorage.removeItem(`feeTx-${pendingTransaction[0].from}`);

                    await addTokenToMetaMask();
                }
            }

        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        // Clean up the interval on component unmount
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);

    const connectToPlug = async () => {
        setShowPopup(true);
        setPopupType('waitforapprove');

        try {
            const hasAllowed = await window.ic?.plug?.requestConnect({
                whitelist: WHITELIST,
            });

            if (hasAllowed) {
                let principal = await window.ic.plug.agent.getPrincipal();
                let plugPrincipal = Principal.from(principal).toString();
                setPrincipal(plugPrincipal);

                const savedFeeTransactionHash = localStorage.getItem(`feeTx-${plugPrincipal}`);
                const shouldPayFee = localStorage.getItem(`payFee-${plugPrincipal}`);

                const qroActor = await window.ic.plug.createActor({ canisterId: QRO_TOKEN_CANISTER_ID, interfaceFactory: QROIDL });
                const bridgeActor = await window.ic.plug.createActor({ canisterId: QRO_BRIDGE_CANISTER_ID, interfaceFactory: QROBridge });
                const qroBalance = await qroActor.icrc1_balance_of({ owner: principal, subaccount: [] });

                // Check for pending transactions
                const pendingTransaction = await bridgeActor.getTransaction();
                let shoudRunTimer = false;

                setShowPopup(false);
                setIsLoggedInWithPlug(true);

                if (pendingTransaction?.length > 0) {
                    setBalance(convertICRCAmount(pendingTransaction[0].amount));
                    setEthAddress(pendingTransaction[0].to);
                    setIAgree(true);
                    setHavePendingTransaction(true);

                    await connectToMetaMask();

                    if ((shouldPayFee === true) && !savedFeeTransactionHash) {
                        await payBridgeFeeInETH();
                    }

                    if (pendingTransaction[0].ethTransferTx) {
                        clearInterval(intervalId);
                        setIntervalId(null);
    
                        setPopupMessage(pendingTransaction[0].ethTransferTx);
                        setPopupType('bridge-successful');
                        setShowPopup(true);
                        setCompleted(true);
                        localStorage.removeItem(`feeTx-${pendingTransaction[0].from}`);
                        localStorage.removeItem(`payFee-${pendingTransaction[0].from}`);
                    } else {
                        shoudRunTimer = true;
                        showPopupWaitForTransaction();
                    }

                } else {
                    let convertedQROBalance = convertICRCAmount(qroBalance);

                    if (convertedQROBalance < MINIMUM_QRO_BALANCE) {
                        setIsLoggedInWithPlug(false);
                        showPopupWarning(`Your ICRC1 QRO balance is ${convertedQROBalance}, which is less than the minimum required balance of ${MINIMUM_QRO_BALANCE} QRO.`);
                    } else {
                        setBalance(convertedQROBalance);
                    }
                }

                if (shoudRunTimer && !intervalId) {
                    const id = setInterval(fetchPendingTx, 10000);
                    setIntervalId(id);
                }
            }
        } catch (error) {
            console.log(error);
            showPopupErrorWithReload('Unable to login.');
        }
    }

    const convertICRC_QRO = async () => {
        if (!iAgree) {
            setShowPopup(true);
            setPopupType('info');
            setPopupMessage('You must agree to the Terms of Use before proceeding.');

            return;
        }
        
        setShowPopup(true);
        setPopupType('waitforapprove');

        try {
            const hasAllowed = await window.ic?.plug?.requestConnect({
                whitelist: WHITELIST,
            });

            if (hasAllowed) {
                let principal = await window.ic.plug.agent.getPrincipal();
                //let accountId = getAccountId(principal);
                let plugPrincipal = Principal.from(principal).toString();
                setPrincipal(plugPrincipal);

                const qroActor = await window.ic.plug.createActor({ canisterId: QRO_TOKEN_CANISTER_ID, interfaceFactory: QROIDL });
                const bridgeActor = await window.ic.plug.createActor({ canisterId: QRO_BRIDGE_CANISTER_ID, interfaceFactory: QROBridge });
                const qroBalance = await qroActor.icrc1_balance_of({ owner: principal, subaccount: [] });

                // Check for pending transaction
                let pendingTransaction = await bridgeActor.getTransaction();
                if (pendingTransaction?.length > 0) {
                    setHavePendingTransaction(true);
                    showPopupWaitForTransaction();
                    return;
                }

                const approvalResult = await qroActor.icrc2_approve({
                    amount: qroBalance,
                    created_at_time: [],
                    expected_allowance: [],
                    expires_at: [],
                    fee: [],
                    from_subaccount: [],
                    memo: [],
                    spender: {
                        owner: Principal.fromText(QRO_BRIDGE_CANISTER_ID),
                        subaccount: []
                    },
                });

                let base64ethAddress = ethAddressToBase64(ethAddress);

                if (verifyResult(approvalResult)) {
                    const fee = 20_000n;
                    const transferResult = await bridgeActor.transfer(
                        qroBalance - fee, // 100_000_000n, = 1QRO
                        ethAddress,
                        base64ethAddress,
                    );

                    switch (transferResult) {
                        case RESULT.statusTransferSuccessful: {
                            localStorage.setItem(`payFee-${plugPrincipal}`, true);
                            await payBridgeFeeInETH();
                        };
                            break;
                        case RESULT.statusErrorTransferFailed:
                        case RESULT.statusErrorDuringTransfer: {
                            showPopupErrorWithReload('Transfer error.');
                        };
                            break;
                        case RESULT.statusErrorPendingTransactionExists: {
                            setHavePendingTransaction(true);
                            showPopupWaitForTransaction();
                        };
                            break;
                        case RESULT.statusErrorPendingLimitExceeded: {
                            showPopupErrorWithReload('To many transactions try again later.');
                        };
                            break;
                        case RESULT.statusErrorInvalidDestinationAddress: {
                            showPopupErrorWithReload('Transfer error, invalid destination address.');
                        };
                            break;
                        case RESULT.statusErrorInvalidAmount: {
                            showPopupErrorWithReload('Transfer error, invalid amount.');
                        };
                            break;
                        case RESULT.statusPaused: {
                            showPopupErrorWithReload('The Bridge is currently paused try again later.');
                        };
                            break;
                        default: {
                            showPopupErrorWithReload('Transfer error, unexpected return code.');
                        }
                            break;
                    }
                } else {
                    showPopupErrorWithReload('Approval error.');
                }
            }
        } catch (error) {
            console.log(error);
            showPopupErrorWithReload('Convert error.');
        }
    }

    const verifyConnectionWithPlug = async () => {
        let connected = await window.ic.plug.isConnected();

        if (!connected) {
            connected = window.ic.plug.requestConnect({ whitelist: WHITELIST });
        }
        if (connected && !window.ic.plug.agent) {
            window.ic.plug.createAgent({ whitelist: WHITELIST })
        }
        return connected;
    };

    const connectToMetaMask = async (onClick = false) => {
        if (window.ethereum) {
            try {
                // Request account access if needed
                await window.ethereum.request({ method: 'eth_requestAccounts' });

                // We use window.ethereum to create a new Web3 instance
                const web3 = new Web3(window.ethereum);

                // Get list of accounts of the connected wallet
                const accounts = await web3.eth.getAccounts();
                setEthAddress(accounts[0]); // Set the first account as the user address

                // Get the connected Network ID
                const networkId = await web3.eth.net.getId();
                const networks = {
                    1: 'Mainnet',
                    3: 'Ropsten',
                    4: 'Rinkeby',
                    5: 'Goerli',
                    42: 'Kovan',
                    11155111: 'Sepolia'
                    // Add other networks as needed
                };
                setCurrentNetwork(networks[networkId] || 'Unknown');

                if (networkId != 1) {
                    showPopupErrorWithReload('Unable to connect to the Mainnet.');
                    return;
                }

                if (onClick) {
                    const amountInEth = FEE;
                    const amountInWei = web3.utils.toWei(amountInEth, 'ether');

                    // Define the gas limit
                    const gasLimit = 21000; // Standard gas limit for ETH transfer
                    // Check the sender's balance
                    const senderBalance = await web3.eth.getBalance(accounts[0]);
                    const requiredBalance = BigInt(amountInWei) + BigInt(gasLimit) * BigInt(await web3.eth.getGasPrice());

                    // Convert required balance from Wei to ETH
                    const requiredBalanceInEth = web3.utils.fromWei(requiredBalance.toString(), 'ether');
                    // Round to 4 decimal places
                    const requiredBalanceInEthRounded = Number.parseFloat(requiredBalanceInEth).toFixed(4);



                    if (BigInt(senderBalance) < requiredBalance) {
                        showPopupWarning(`Insufficient funds to complete the transaction. Minimum balance required: ${requiredBalanceInEthRounded.toString()} ETH`);
                        return;
                    }
                }

                setIsLoggedInWithMetamask(true);

                if (havePendingTransaction && intervalId) {
                    showPopupWaitForTransaction();
                }

            } catch (error) {
                showPopupError('Could not connect to MetaMask.');
            }
        } else {
            setPopupMessage('MetaMask is not installed. Please consider installing it and reload the current page.')
            setPopupType('errorwithreload');
            setShowPopup(true);
        }
    };

    const addTokenToMetaMask = async () => {
        if (window.ethereum) {
            try {
                // wasAdded will be true if the token was successfully added
                await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: QRO_TOKEN,
                            symbol: 'QRO',
                            decimals: 18,
                            image: 'https://github.com/QuerioDAO/Assets/blob/251afe669957e34770b18bab0f1940a9e2de1d9a/logo.png',
                        },
                    },
                });
            } catch (error) {
                console.error('Failed to add token', error);
            }
        }
    }
    

    const payBridgeFeeInETH = async () => {
        setShowPopup(true);
        setPopupType('waitforconfirm');

        if (window.ethereum) {
            try {
                // Request account access if needed
                await window.ethereum.request({ method: 'eth_requestAccounts' });

                // We use window.ethereum to create a new Web3 instance
                const web3 = new Web3(window.ethereum);

                // Get list of accounts of the connected wallet
                const accounts = await web3.eth.getAccounts();
                if (accounts?.length < 1) {
                    showPopupErrorWithReload('Unable to connect to the MetaMask wallet.');
                    return;
                }

                // Get the connected Network ID
                const networkId = await web3.eth.net.getId();
                const networks = {
                    1: 'Mainnet',
                    3: 'Ropsten',
                    4: 'Rinkeby',
                    5: 'Goerli',
                    42: 'Kovan',
                    11155111: 'Sepolia'
                    // Add other networks as needed
                };
                setCurrentNetwork(networks[networkId] || 'Unknown');

                if (networkId != 1) {
                    showPopupErrorWithReload('Unable to connect to the Mainnet.');
                    return;
                }

                // Fetch current gas price from the network

                if (accounts[0] != ethAddress) {
                    showPopupErrorWithReload(`Connect with MetaMask using the following acount: ${ethAddress}`);
                    return;
                }

                const sender = accounts[0];

                // Fixed amount to transfer
                const amountInEth = FEE;
                const amountInWei = web3.utils.toWei(amountInEth, 'ether');

                // Define the gas limit
                const gasLimit = 21000; // Standard gas limit for ETH transfer

                let tx;

                try {
                    // Send the transaction
                    tx = await web3.eth.sendTransaction({
                        from: sender,
                        to: QRO_BRIDGE,
                        value: amountInWei,
                        gas: gasLimit,
                    });

                } catch (error) {
                    console.log(error);
                }

                let payResult = false;
                if (tx.status === 1 || tx.status === 1n) {
                    // Set the transaction hash
                    localStorage.setItem(`feeTx-${principal}`, tx.transactionHash);
                    localStorage.setItem(`payFee-${principal}`, false);
                    payResult = true;

                    setHavePendingTransaction(true);
                    showPopupWaitForTransaction();
                    if (!intervalId) {
                        const id = setInterval(fetchPendingTx, 10000);
                        setIntervalId(id);
                    }

                } else {
                    setPopupMessage('Unable to process transaction. Please reload the current page.')
                    setPopupType('errorwithreload');
                    setShowPopup(true);
                }

                return payResult;
            } catch (error) {
                console.error('Could not connect to MetaMask', error.message);
            }
        } else {
            setPopupMessage('MetaMask is not detected. Please reload the current page.')
            setPopupType('errorwithreload');
            setShowPopup(true);
        }

        return false;
    };

    const handleAgree = () => {
        setIAgree(!iAgree);
    }

    return (
        <>
            <AssetHeader icon={principal} text={trimPrincipal(principal)} />


            {showPopup && <Popup type={popupType} message={popupMessage} setShowPopup={setShowPopup} />}

            {/*showVestingClaim && <Claim amountQRO={amountToClaimQRO} onClaimed={onClaimed} />*/}
            {/*showPopup && <Popup type={popupType} message={popupMessage} setShowPopup={setShowPopup} onClaim={onClaim} onLogin={(e) => { e.preventDefault(); handleLoginWithPlug() }} />*/}

            {/*!accessGranted ? (
                <Box sx={{ maxWidth: 300, margin: 'auto', textAlign: 'center', mt: 5 }}>
                    <Typography variant="h5" gutterBottom>
                        Enter Password
                    </Typography>
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <Button variant="contained" color="primary" onClick={verifyPassword}>
                        Submit
                    </Button>
                </Box>
            ) : ( */}
            <Container
                // maxWidth="xl"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    // justifyContent: 'center',
                    // minHeight: '100vh',
                    minHeight: '100vh',
                    height: '100%',
                    boxShadow: 3,
                    overflow: 'auto',
                    paddingTop: '2rem'
                }}
            >
                <img
                    src={QuerioLogo}
                    alt="Querio Logo"
                    style={{ width: matches ? '150px' : '75px', marginTop: '2rem', marginBottom: '2rem', cursor: 'pointer' }}
                    onClick={() => navigate("/")}
                />
                <Typography
                    sx={{
                        fontSize: '32px',
                        fontWeight: 500,
                        lineHeight: '44px',
                        letterSpacing: '0rem',
                        textAlign: 'left',
                        marginBottom: '1.5rem'
                    }}
                >
                    Querio Bridge
                </Typography>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        letterSpacing: '0rem',
                        textAlign: 'left',
                        marginBottom: '3.5rem',
                        width: '50%',

                    }}
                >
                    Effortlessly convert your ICRC-1 QRO tokens to ERC-20 QRO tokens at a 1:1 exchange rate. 
                    <br />
                    <br />
                    1. Connect with Plug
                    <br />
                    2. Connect with MetaMask
                    <br />
                    3. Convert ICRC-1 QRO
                    <br />
                    4. Please wait while the bridge processes your transaction
                    <br />
                    <br />
                    <ListItemButton
                        href="https://oc.app/community/qbzct-jaaaa-aaaar-au2gq-cai/?ref=jviq4-waaaa-aaaar-aqq7a-cai"
                        sx={{
                            paddingX: 0,
                            display: 'inline-flex',
                            justifyContent: 'left',
                            width: '5rem'
                        }}
                    >
                        <img src={OpenChatLogo} alt='x' style={{ width: '1.65rem', height: '1.65rem' }} />
                    </ListItemButton>

                    <ListItemButton
                        href="https://discord.com/invite/Jvb8Xmzgdv"
                        sx={{
                            paddingX: 0,
                            display: 'inline-flex',
                            justifyContent: 'left',
                            width: '5rem'
                        }}
                    >
                        <img src={mode === 'light' ? DiscordLogoBlack : DiscordLogo} alt='x' style={{ width: '1.85rem', height: '1.65rem' }} />
                    </ListItemButton>
                    <ListItemButton
                        href="https://t.me/+VXpWvtRUSyU0MmFk"
                        sx={{
                            paddingX: 0,
                            display: 'inline-flex',
                            justifyContent: 'left',
                            width: '5rem'
                        }}
                    >
                        <img src={TelegramLogo} alt='x' style={{ width: '1.85rem', height: '1.85rem' }} />
                    </ListItemButton>
                </Typography>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        marginBottom: '3.5rem',
                        marginRight: 'auto',
                        marginLeft: 'auto'
                    }}
                >
                    {/* Button centered within a full-width Box */}
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Button
                            variant='contained'
                            onClick={() => {
                                connectToPlug();
                            }}
                            disabled={isLoggedInWithPlug}
                            sx={{
                                backgroundColor: '#FBB03B',
                                height: '2.5rem',
                                width: '16rem',
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: '24px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                textTransform: 'none',
                                '&: hover': {
                                    backgroundColor: '#FFBA4E',
                                },
                            }}
                        >
                            
                            1. Connect with Plug
                            { isLoggedInWithPlug && <CheckCircleIcon style={{ color: 'green' }} /> }
                            
                        </Button>
                        
                    </Box>
                </Stack>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        marginBottom: '4rem',
                        marginRight: 'auto',
                        marginLeft: 'auto'
                    }}
                >
                    {/* Button centered within a full-width Box */}
                    { isLoggedInWithPlug && <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Button
                            variant='contained'
                            onClick={() => {
                                connectToMetaMask(true);
                            }}
                            disabled={isLoggedInWithMetamask}
                            sx={{
                                backgroundColor: '#FBB03B',
                                height: '2.5rem',
                                width: '16rem',
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: '24px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                textTransform: 'none',
                                '&: hover': {
                                    backgroundColor: '#FFBA4E',
                                },
                            }}
                        >
                            2. Connect with MetaMask
                            { isLoggedInWithMetamask && <CheckCircleIcon style={{ color: 'green' }} /> }
                        </Button>
                    </Box> }
                </Stack>
                {(isLoggedInWithMetamask) &&
                    <>
                        <Stack sx={{ width: '100%', marginBottom: '2rem' }}>

                            <Divider sx={{ marginBottom: '4rem', width: '100%' }} />
                            <Stack direction='row' justifyContent='center' alignItems='center' sx={{ marginBottom: '3rem' }}>

                                <img
                                    src={QuerioLogo}
                                    alt='QuerioLogo'
                                    style={{
                                        height: '1.7rem',
                                        width: '1.7rem',
                                        marginRight: '0.3rem'
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        letterSpacing: '0rem',
                                        textAlign: 'left',
                                    }}
                                >
                                    {balance} QRO
                                </Typography>
                            </Stack>
                            <Stack direction='row' justifyContent='center'>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        letterSpacing: '0rem',
                                        textAlign: 'left',
                                        marginRight: '1rem'
                                    }}
                                >
                                    ICRC-1 address:
                                </Typography>
                                <Stack direction='row' justifyContent='center' alignItems='center'>

                                    {/* <img
                                        src={QuerioLogo}
                                        alt='QuerioLogo'
                                        style={{
                                            height: '1.7rem',
                                            width: '1.7rem',
                                            marginRight: '0.3rem'
                                        }}
                                    /> */}
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '24px',
                                            letterSpacing: '0em',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {principal}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction='row' justifyContent='center' sx={{ marginBottom: '2rem' }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        marginRight: '1rem'
                                    }}
                                >
                                    ERC-20 address:
                                </Typography>
                                <Stack direction='row' justifyContent='center' alignItems='center'>
                                    {/* <img
                                        src={QuerioLogo}
                                        alt='QuerioLogo'
                                        style={{
                                            height: '1.7rem',
                                            width: '1.7rem',
                                            marginRight: '0.3rem'
                                        }}
                                    /> */}
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '24px',
                                            letterSpacing: '0em',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {ethAddress}
                                </Typography>

                            </Stack>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='flex-start'
                            sx={{
                                marginBottom: '3rem'
                            }}
                        >
                            {/*<Checkbox
                                checked={iAgree}
                                onChange={handleAgree}
                                disabled={havePendingTransaction}
                                sx={{
                                    color: '#000',
                                    '&.Mui-checked': {
                                        color: '#000',
                                    },
                                    padding: 0,
                                    marginRight: '1rem'
                                }}
                            />*/}
                            <Box sx={{ width: '50%', maxWidth: '50%', padding: 0,}}>
                            <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '44px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        marginRight: '0.5rem'
                                    }}
                                >
                                    Terms of Use
                                </Typography>
                                <Box
                                    sx={{
                                        maxHeight: 200,
                                        overflow: 'auto',
                                        border: '1px solid',
                                        borderColor: 'grey.300',
                                        p: 2,
                                        mb: 2,
                                        padding: 0,
                                    }}
                                >
                                    <Typography sx={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        lineHeight: '18px',
                                        letterSpacing: '0.005rem',
                                        textAlign: 'left',
                                        marginRight: '0.5rem'
                                    }}
                                    >
                                        <br />
                                        1. <strong>One-Way Conversion:</strong> The bridge allows conversion from QRO ICRC-1 tokens to ERC-20 QRO tokens only. It is not possible to convert ERC-20 tokens back to ICRC-1.
                                        <br />
                                        <br />
                                        2. <strong>Exchange Rate:</strong> The conversion between ICRC-1 QRO tokens and ERC-20 QRO tokens is fixed at a 1:1 rate, ensuring a direct and equivalent transfer of value.
                                        <br />
                                        <br />
                                        3. <strong>Gas Fees Responsibility:</strong> You are responsible for the gas fees required by the conversion from ICRC-1 QRO to ERC-20 QRO, which is about 0.002 ETH.
                                        <br />
                                        <br />
                                        4. <strong>Transparency and Tracking:</strong> Note that the bridge is not designed to conceal funds between the ICP and Ethereum blockchains. The destination ERC-20 address is recorded in the memo field of the ICRC-1 QRO ledger canister, enabling the tracking of transactions between the two blockchains.
                                        <br />
                                        <br />
                                        5. <strong>Wallet Security Responsibility:</strong> Ensure all necessary precautions are taken to safeguard your wallet information and assets. You are responsible for the security of your MetaMask and Plug wallets.
                                        <br />
                                        <br />
                                        6. <strong>Confidentiality of Wallet Seed Phrases:</strong> Never share your seed phrases or private keys with anyone. Disclosure of these details can compromise the security of your assets.
                                        <br />
                                        <br />
                                        7. <strong>Usage Agreement:</strong> By using the QRO Token Bridge, you agree to these terms, acknowledging that maintaining the confidentiality and security of your wallet is your responsibility.
                                        <br />
                                        <br />
                                    </Typography>
                                </Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={iAgree}
                                            onChange={handleAgree}
                                            disabled={havePendingTransaction}
                                            name="acceptTerms"
                                            color="primary"
                                            sx={{
                                                color: 'grey.300',
                                                '&.Mui-checked': {
                                                    color: 'grey.300',
                                                },
                                                padding: 0,
                                                marginRight: '0.5rem',
                                                marginLeft: '0.5rem'
                                            }}
                                        />
                                    }
                                    label="I have read and agree to the Terms of Use"
                                />
                            </Box>

                        </Stack>
                            <Box sx={{ width: '100%', textAlign: 'center', marginBottom: '3rem' }}>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        convertICRC_QRO();
                                    }}
                                    disabled={havePendingTransaction}
                                    sx={{
                                        backgroundColor: '#FBB03B',
                                        height: '2.5rem',
                                        width: '16rem',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        textTransform: 'none',
                                        '&: hover': {
                                            backgroundColor: '#FFBA4E',
                                        },
                                    }}
                                >
                                    3. Convert ICRC-1 QRO
                                    { havePendingTransaction && <CheckCircleIcon style={{ color: 'green' }} /> }
                                </Button>
                            </Box>
                        </Stack>
                        
                    </>}
            </Container > {/*})}*/}
        </>
    )
}
export default Bridge;