import {
    Tabs,
} from "@mui/material";

import styled from '@emotion/styled';


export const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))(({ theme }) => ({
    // tab position
    marginLeft: '-1rem',
    marginTop: '-1rem',

    // underline properties
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        marginLeft: '0.6rem',
        maxWidth: 90,
        width: '100%',
        backgroundColor: theme.palette.text.secondary,
    },
})
);
