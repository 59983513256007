import {
    Box,
} from "@mui/material";

import styled from '@emotion/styled';

export const LogoSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    // paddingTop: '19rem',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1rem',
    'img': {
        width: '15rem',
        height: '8rem',
        // marginTop: 'auto',
        objectFit: 'contain',
        objectPosition: 'center',
    },
    [theme.breakpoints.down('sm')]: {
        // paddingTop: '10rem',
        'img': {
            width: '12rem',
            height: '5rem',
            // marginTop: 'auto',
        },
    },
}));