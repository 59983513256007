export const idlFactory = ({ IDL }) => {
  const FeedItem = IDL.Record({
    'key' : IDL.Text,
    'url' : IDL.Text,
    'article_title' : IDL.Text,
    'pub_date' : IDL.Text,
    'source' : IDL.Text,
    'article_image_data' : IDL.Text,
    'icon_data' : IDL.Text,
  });
  const Feed = IDL.Service({
    'add' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Text],
        [IDL.Int],
        [],
      ),
    'balance' : IDL.Func([], [IDL.Nat], ['query']),
    'get' : IDL.Func([IDL.Text], [FeedItem], ['query']),
    'get_items' : IDL.Func([IDL.Vec(IDL.Text)], [IDL.Vec(FeedItem)], ['query']),
    'get_list' : IDL.Func([], [IDL.Vec(IDL.Text)], ['query']),
  });
  return Feed;
};
export const init = ({ IDL }) => { return []; };
