import {
    Tab,
} from "@mui/material";

import styled from '@emotion/styled';


export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        // text properties
        textTransform: 'none',
        // color: theme.palette.text.primary,
        color: theme.palette.tabsColors.main,
        fontWeight: 500,
        fontFamily: 'sans-serif',
        fontSize: '15px',
        letterSpacing: '0px',
        '&.Mui-selected': {
            color: theme.palette.text.secondary, // Adjust the text color
            //borderBottomWidth: '3px',
            //borderBottomStyle: 'solid',
            //borderBottomColor: '#29ABE2',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '&.MuiButtonBase-root': {
            paddingBottom: '0rem',
        },
        '&:hover': {
            color: theme.palette.text.secondary, // Adjust the text color on hover
        },

        minWidth: '3rem',
        width: '7rem',
        minHeight: '4.5rem', // Lower the height of the tab
    }),
);