import {
    Box,
} from "@mui/material";

import styled from '@emotion/styled';


export const SearchContainer = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.primary.main,
    paddingTop: '3rem',
    paddingRight: '1.4rem',


    [theme.breakpoints.down('md')]: {
        paddingLeft: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
        paddingRight: '1.4rem',
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: '7.7rem',
    },
    [theme.breakpoints.up('lg')]: {
        paddingLeft: '14.7rem',
    },
}));