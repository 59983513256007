import {
    Stack,
    Typography,
    Box,
} from "@mui/material"


export default function ShortcutButton({ logo, title, url }) {
    return (
        <Box
            sx={{
                margin: {
                    xxl: '2rem',
                    xl: '2rem',
                    lg: '2rem',
                    md: '2rem',
                    sm: '1.25rem',
                    xs: '1.25rem'
                },
                backgroundColor: 'transparent',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', /* Center elements vertically */
            }}
        >
            <a href={url} style={{ textDecoration: 'none' }}>
                <Stack justifyContent='column' alignContent="center">

                    <img
                        src={logo}
                        alt='logo'
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '0.5rem'
                        }}
                    />
                    <Typography sx={{ color: '#909090', fontSize: 16, textAlign: 'center' }}>{title}</Typography>

                </Stack>
            </a>
        </Box>
    );
}