import ICPLogo from '../assets/ICPLogo.svg';
import ETHLogo from '../assets/ETHLogo.svg';
import XLMLogo from '../assets/XLMLogo.svg';
import NEARLogo from '../assets/NEARLogo.svg';
import BNBLogo from '../assets/BNBLogo.svg';
import SOLLogo from '../assets/SOLLogo.svg';
import ADALogo from '../assets/ADALogo.svg';
import DOTLogo from '../assets/DOTLogo.svg';
import MATICLogo from '../assets/MATICLogo.svg';
import ARBLogo from '../assets/ARBLogo.svg';
import OPLogo from '../assets/OPLogo.svg';

export const chains = [
    { title: "ICP", icon: ICPLogo },
    { title: "ETH", icon: ETHLogo },
    { title: "XLM", icon: XLMLogo },
    { title: "NEAR", icon: NEARLogo },
    { title: "BNB", icon: BNBLogo },
    { title: "SOL", icon: SOLLogo },
    { title: "ADA", icon: ADALogo },
    { title: "DOT", icon: DOTLogo },
    { title: "MATIC", icon: MATICLogo },
    { title: "ARB", icon: ARBLogo },
    { title: "OP", icon: OPLogo },
];

export const chainMap = {
    icp: 1,
    eth: 2,
    xlm: 3,
    near: 4,
    bnb: 5,
    sol: 6,
    ada: 7,
    dot: 8,
    matic: 9,
    arb: 10,
    op: 11
};

export const reverseChainMap = Object.keys(chainMap).reduce((acc, key) => {
    const value = chainMap[key];
    acc[value] = key;
    return acc;
}, {});