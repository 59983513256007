import React from "react";
import { useState } from "react";
import {
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Container,
    Typography,
    Card,
    CardContent,
    useMediaQuery,
} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import { Principal } from '@dfinity/principal';
import { idlFactory as SeedFundersBackendIDL } from '../idls/seed_funders_backend.did';
import QuerioLogo from '../assets/QuerioLogo.svg'
import Popup from './Popup';
import { AgreementNFTs } from './AgreementNFTs';
import { createGlobalStyle } from 'styled-components';

const { QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID } = require('../config');


const GlobalStyle = createGlobalStyle`
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const WHITELIST = [QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID];

function trimPrincipal(principal) {
    const firstDashIndex = principal.indexOf('-');
    const lastDashIndex = principal.lastIndexOf('-');

    return `${principal.substring(0, firstDashIndex + 1)}...${principal.substring(lastDashIndex)}`;
}

function isValidPrincipal(p) {
    try {
        return (p === Principal.fromText(p).toText());
    } catch (e) {
        return false;
    }
}

function codeToMessage(code) {
    switch (code) {
        case 0:
            return "Not an NFT holder.";
        case 1:
            return "Successful.";
        case 3:
            return "Reward already claimed.";
        case 2:
            return "Please agree to the terms before proceeding.";
        default:
            return "Unrecognized error code.";
    }
}

const Claim = ({ amountQRO, onClaimed }) => {
    const [recipientPrincipal, setRecipientPrincipal] = useState();
    const [isValidRecipientPrincipal, setIsValidRecipientPrincipal] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const matches = useMediaQuery('(min-width:600px)');

    const handleCheckboxChange = () => {
        setAcceptedTerms(!acceptedTerms);
    };

    const verifyConnectionWithPlug = async () => {
        let connected = await window.ic.plug.isConnected();
        if (!connected) {
            connected = await window.ic.plug.requestConnect({ whitelist: WHITELIST });
        }
        return connected;
    };

    const handleChangeRecipientPrincipalId = e => {
        setRecipientPrincipal(e.target.value);
    };

    const showPopupError = (message) => {
        setShowPopup(true);
        setPopupType('error');
        setPopupMessage(message);
    }
    const showPopupWarning = (message) => {
        setShowPopup(true);
        setPopupType('warning');
        setPopupMessage(message);
    }

    const showPopupInfo = (message) => {
        setShowPopup(true);
        setPopupType('info');
        setPopupMessage(message);
    }

    const showPopupSuccessful = (message) => {
        setShowPopup(true);
        setPopupType('successful');
        setPopupMessage(message);
    }

    async function handleClaimClick(el) {
        const validRecipient = isValidPrincipal(recipientPrincipal);
        setIsValidRecipientPrincipal(validRecipient);

        if (!validRecipient) {
            showPopupWarning('Please provide a valid recipient principal ID.');
            return;
        }

        if (!acceptedTerms) {
            showPopupWarning('Please agree to the terms before proceeding.');
            return;
        }


        try {
            let hasAllowed = false;
            try {
                hasAllowed = await verifyConnectionWithPlug();
            } catch (error) {
                showPopupError('Connection to Plug failed.');
            }


            if (hasAllowed) {
                setShowPopup(true);
                setPopupType('saving');

                const actor = await window.ic.plug.createActor({ canisterId: QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID, interfaceFactory: SeedFundersBackendIDL });

                const response = await actor.claimNFTs(
                    recipientPrincipal,
                    acceptedTerms,
                );

                if (response === 1) {
                    showPopupSuccessful('You have successfully claimed your Querio NFT rewards.');
                } else {
                    showPopupError('Claim Error: ' + codeToMessage(response));
                }

            } else {
                showPopupError('Plug wallet connection was refused, please retry.');
            }
        } catch (error) {
            showPopupError('Claim Error.');
        }
    }

    const onSuccesfulClaim = () => {
        setShowPopup(false);
        onClaimed();
    }

    return (
        <>
            {showPopup && <Popup type={popupType} message={popupMessage} setShowPopup={setShowPopup} onSuccesfulClaim={onSuccesfulClaim}/>}

            <Container
                // maxWidth="xl"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // minHeight: '100vh',
                    height: '100%',
                    boxShadow: 3,
                    overflow: 'auto',
                    paddingTop: '2rem'
                }}
            >

                <img src={QuerioLogo} alt="Querio Logo" style={{ width: matches ? '150px' : '75px', marginTop: '2rem', marginBottom: '2rem' }} />

                <Typography
                    sx={{
                        fontSize: '36px',
                        fontWeight: 500,
                        lineHeight: '44px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        marginBottom: '2rem'
                    }}
                >
                    Claim NFT Rewards
                </Typography>

                <Card
                    variant="outlined"
                    sx={{
                        marginBottom: '16px',
                        width: '75%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        //height: '100%',
                        '@media (maxWidth:600px)': {
                            width: '100%',
                        }
                    }}
                >
                    <CardContent
                        sx={{
                            padding: '2rem',
                        }}
                    >
                        <div style={{ position: 'relative', marginBottom: '16px' }}>
                            <TextField
                                label={"Amount QRO"}
                                variant="outlined"
                                autoComplete="off"
                                fullWidth
                                type="number"
                                value={amountQRO}
                                InputLabelProps={{ shrink: true }}
                                onWheel={(e) => e.target.blur()}
                                disabled={true}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        backgroundColor: '#ebebeb'
                                    },
                                }}
                            />
                        </div>
                        <div style={{ position: 'relative', marginBottom: '16px' }}>
                            <TextField
                                value={recipientPrincipal}
                                label="NNS Principal ID to receive QRO tokens"
                                autoComplete="off"
                                onChange={handleChangeRecipientPrincipalId}
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                    marginRight: '1rem',
                                    marginLeft: 'auto',
                                    '& .MuiOutlinedInput-root': {
                                        // Styles for the input field
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#99999980',
                                        },
                                        '& fieldset': {
                                            borderColor: '#99999980',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        // Styles for the label in all states
                                        color: '#999999',
                                    },
                                    '& .MuiInputLabel-outlined.Mui-focused': {
                                        // Specific styles for the focused label, if needed
                                        color: '#999999',
                                    },
                                    '& .MuiInputLabel-shrink': {
                                        // This ensures the label shrinks and moves up
                                        transform: 'translate(14px, -9px) scale(0.75)',
                                    },
                                }}
                            />
                            <Typography paragraph style={{ color: '#919191', fontSize: '12px', paddingLeft: '12px' }}>
                                * How to find your NNS Principal ID: <a href="https://x.com/querio_io/status/1716381201336770798?s=46" target="_blank" rel="noopener noreferrer">X/Twitter post</a> <br />
                            </Typography>
                            {!isValidRecipientPrincipal && <ErrorIcon style={{ position: 'absolute', right: '10px', top: '55%', transform: 'translateY(-100%)', color: 'red' }} />}
                        </div>
                    </CardContent>
                </Card>

                <Typography
                    sx={{
                        marginRight: 'auto',
                        width: '75%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        //height: '100%',
                        '@media (maxWidth:600px)': {
                            width: '100%',
                        }
                    }}
                >
                    Token Claim Agreement and GDPR
                </Typography>

                <TextField
                    type='text'
                    multiline
                    maxRows={25}
                    sx={{
                        padding: '8px',
                        borderRadius: '4px',
                        borderColor: '#ccc',
                        overflow: 'auto',
                        width: '76%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        //height: '100%',
                        '@media (maxWidth:600px)': {
                            width: '100%',
                        }
                    }}
                    defaultValue={AgreementNFTs}
                    inputProps={
                        { readOnly: true, }
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={acceptedTerms}
                            onChange={handleCheckboxChange}
                            sx={{
                                color: '#006691',
                                '&.Mui-checked': {
                                    color: '#006691',
                                },
                            }}
                        />
                    }
                    label="I agree to the terms of the Token Claim Agreement and GDPR"
                    sx={{
                        marginBottom: '8px'
                    }}
                />

                <div className="input-container" style={{ textAlign: 'center', marginBottom: '5rem' }}>
                    <Button
                        variant="contained"
                        onClick={handleClaimClick}
                        sx={{
                            backgroundColor: '#57B660',
                            marginBottom: '5rem',
                        }}
                    >
                        Claim
                    </Button>
                </div>


            </Container >
        </>
    );
};

export default Claim;
